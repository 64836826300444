import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { brandStylesByRelationId, flatColorsByRelationId, flatFontsByRelationId } from 'containers/Common/selectors';
import * as Models from 'models';

export type BrandProps = {
  brandStyles: Models.BrandStylesMap;
  colors: Models.BrandColorsList;
  fonts: Models.BrandFontsList;
};

export default function useBrandProps(relationId: string): BrandProps {
  const brandStyles = useSelector(brandStylesByRelationId);
  const flatColors = useSelector(flatColorsByRelationId);
  const flatFonts = useSelector(flatFontsByRelationId);

  return useMemo(
    (): BrandProps => ({
      brandStyles: brandStyles.get(relationId),
      colors: flatColors.get(relationId),
      fonts: flatFonts.get(relationId),
    }),
    [relationId, brandStyles, flatColors, flatFonts],
  );
}
