import { $getSelectionStyleValueForProperty } from '@lexical/selection';
import { $findMatchingParent, $getNearestBlockElementAncestorOrThrow } from '@lexical/utils';
import {
  $getSelection,
  $isElementNode,
  $isNodeSelection,
  $isRangeSelection,
  $isTextNode,
  ElementFormatType,
  ElementNode,
  LexicalNode,
  PointType,
} from 'lexical';
import { $isCustomParagraphNode } from 'modules/Lexical/nodes/CustomParagraphNode';
import * as Constants from 'const';
import { NO_WRAP_VALUE, WHITE_SPACE_PROP } from './style';
import { BaseProps } from './types';

const elementNodeFormatMapToBlockType: {
  [key in ElementFormatType]?: Constants.TextHorizontalAlignmentType
} = {
  left: Constants.TextHorizontalAlignmentType.LEFT,
  center: Constants.TextHorizontalAlignmentType.CENTER,
  end: Constants.TextHorizontalAlignmentType.RIGHT,
  justify: Constants.TextHorizontalAlignmentType.JUSTIFY,
};

function getBlockTypeFromSelection(): BaseProps['blockType'] {
  const selection = $getSelection();

  if (!$isRangeSelection(selection) && !$isNodeSelection(selection)) {
    return Constants.TextHorizontalAlignmentType.LEFT;
  }

  for (const node of selection.getNodes()) {
    const element = $findMatchingParent(
      node,
      (parentNode): parentNode is ElementNode => $isElementNode(parentNode) && !parentNode.isInline(),
    );
    if (element) {
      const type: ElementFormatType = (element as ElementNode).getFormatType() ;

      return (type && elementNodeFormatMapToBlockType[type]) || Constants.TextHorizontalAlignmentType.LEFT;
    }
  }

  return Constants.TextHorizontalAlignmentType.LEFT;
}

function getScriptStyleFromSelection(): BaseProps['scriptStyle'] {
  const selection = $getSelection();

  if (!$isRangeSelection(selection)) {
    return undefined;
  }

  if (selection.hasFormat('superscript')) {
    return Constants.ScriptType.SUPERSCRIPT;
  }

  if (selection.hasFormat('subscript')) {
    return Constants.ScriptType.SUBSCRIPT;
  }

  return undefined;
}

function getBlockLineHeightFromSelection(): BaseProps['blockLineHeight'] {
  const selection = $getSelection();

  if ($isRangeSelection(selection)) {
    const anchorNode = selection.anchor.getNode();
    const paragraphNode = anchorNode.getTopLevelElement();

    if ($isCustomParagraphNode(paragraphNode)) {
      const lineHeight = paragraphNode.getLineHeight();
      if (lineHeight) {
        return lineHeight as Constants.TextLineHeightValue;
      }
    }
  }

  return Constants.DefaultLineHeight;
}

function getTextNoWrapFromSelection(): BaseProps['textNoWrap'] {
  const selection = $getSelection();
  if (selection && $isRangeSelection(selection)) {
    return $getSelectionStyleValueForProperty(selection, WHITE_SPACE_PROP) === NO_WRAP_VALUE;
  }

  return false;
}

export function $getBaseProps(): BaseProps {
  return {
    blockLineHeight: getBlockLineHeightFromSelection(),
    blockType: getBlockTypeFromSelection(),
    scriptStyle: getScriptStyleFromSelection(),
    textNoWrap: getTextNoWrapFromSelection(),
  };
}

export function $clearTextNodesFormatting(
  nodes: LexicalNode[],
  extractedNodes: LexicalNode[],
  anchor: PointType,
  focus: PointType): void {
  nodes.forEach((node, idx) => {
    if ($isTextNode(node)) {
      let textNode = node;
      if (idx === 0 && anchor.offset !== 0) {
        textNode = textNode.splitText(anchor.offset)[1] || textNode;
      }
      if (idx === nodes.length - 1) {
        textNode = textNode.splitText(focus.offset)[0] || textNode;
      }

      const extractedTextNode = extractedNodes[0];
      if (nodes.length === 1 && $isTextNode(extractedTextNode)) {
        textNode = extractedTextNode;
      }

      if (textNode.__style !== '') {
        textNode.setStyle('');
      }
      if (textNode.__format !== 0) {
        textNode.setFormat(0);
        $getNearestBlockElementAncestorOrThrow(textNode).setFormat('');
      }
      node = textNode; //NOSONAR
    }
  });
}
