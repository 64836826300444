import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LexicalEditor } from 'lexical';
import { forwardRef, useImperativeHandle } from 'react';

export const EditorRefPlugin = forwardRef<LexicalEditor>((_, ref) => {

  const [editor] = useLexicalComposerContext();
  useImperativeHandle(ref, () => editor);

  return null;
});
