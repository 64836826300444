import classNames from 'classnames';
import React from 'react';

import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { ImageInputStatus } from 'const';
import { intlGet } from 'utils/intlGet';
import { IImageInputProps, IImageInputState } from './models';
import styles from './styles.module.scss';

export default class ImageInput extends React.PureComponent<IImageInputProps, IImageInputState> {
  private readonly handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const imageFile = event.target.files?.item(0);

    if (!imageFile) {
      return;
    }

    const { onInputChange, resolution } = this.props;

    this.setState({
      fileName: imageFile.name,
    });

    onInputChange(resolution, imageFile);
  };

  private readonly getFileName = (): JSX.Element => {
    const {
      imageFileName,
      imageResolutionData: {
        getPlaceholderName,
        displayedImageFilter,
      },
    } = this.props;

    return (
      <span className={styles.placeholderName}>
        {imageFileName || `${getPlaceholderName()} (${displayedImageFilter})`}
      </span>
    );
  };

  private readonly getImageInfo = (): JSX.Element | null => {
    const { imageInputStatus, imageResolutionData: { required } } = this.props;

    if (!required) {
      return <span className={styles.info}>{intlGet('ImageInput', 'Optional')}</span>;
    }
    if (imageInputStatus === ImageInputStatus.CHANGED) {
      return <span className={styles.info}>{intlGet('ImageInput', 'Changed')}</span>;
    }

    return null;
  };

  private readonly getUploadLogo = (): JSX.Element => {
    switch (this.props.imageInputStatus) {
      case ImageInputStatus.EMPTY:
        return <Icon type={IconType.EXPORT_PROJECT} size="sm" color="light-grey" />;
      case ImageInputStatus.INITIAL:
      case ImageInputStatus.ADDED:
        return <span className={classNames(styles.icon, styles.icon_checkMark)}><Icon type={IconType.TICK} size="xs" color="secondary" /></span>;
      case ImageInputStatus.CHANGED:
        return <span className={classNames(styles.icon, styles.icon_changeMark)}><Icon type={IconType.NOTICE} size="sm" color="secondary" /></span>;
    }
  };

  render(): JSX.Element {
    const { imageResolutionData: { imageFilter } } = this.props;

    return (
      <label className={styles.ImageInput}>
        {this.getUploadLogo()}
        {this.getFileName()}
        {this.getImageInfo()}
        <input className={styles.fileInput} type="file" accept={imageFilter} onChange={this.handleChange} />
      </label>
    );
  }
}
