import * as React from 'react';

import UpdateScreen from 'components/UpdateScreen';
import { intlGet } from 'utils/intlGet';
import { IAddScreenProps, IAddScreenState } from './models';

export default class AddScreen extends React.PureComponent<IAddScreenProps, IAddScreenState> {
  private getDefaultScreenName(count = 1): string {
    const { screenNames } = this.props;
    const defaultScreenName = intlGet('Artboard', 'DefaultScreenName', { count });

    if (screenNames.includes(defaultScreenName)) {
      return this.getDefaultScreenName(count + 1);
    }

    return defaultScreenName;
  }

  render(): JSX.Element {
    const {
      addScreen,
      hideModal,
      modalWindowId,
      projectType,
      screenDefinitions,
      screenNames,
    } = this.props;

    return (
      <UpdateScreen
        activeSurfaceDefinitionId={null}
        defaultScreenName={this.getDefaultScreenName()}
        hideModal={hideModal}
        modalWindowId={modalWindowId}
        onSubmit={addScreen}
        projectType={projectType}
        screenDefinitions={screenDefinitions}
        screenNames={screenNames}
        submitButtonTitle={intlGet('AddScreen.Buttons', 'Add')}
        title={intlGet('AddScreen', 'AddScreen')}
      />
    );
  }
}
