import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { DragSource, DragSourceCollector, DragSourceSpec } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import AccessibleDiv from 'components/AccessibleDiv/AccessibleDiv';
import AssetActions from 'components/AssetActions';
import { useScrollIntoView } from 'components/AssetList/hooks';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import LoadingOverlay from 'components/LoadingOverlay';
import { DragSourceType, ModalType, ProcessType } from 'const';
import { Priority, useClickOutside } from 'hooks/useClickOutside';
import { AssetDragObject } from 'models';
import { removeTags } from 'utils/removeTags';
import { trimText } from 'utils/trimText';
import { ICitationItemCollectedProps, ICitationItemOwnProps, ICitationItemProps } from './models';
import styles from './styles.module.scss';

const CitationItemDragSource: DragSourceSpec<ICitationItemProps, AssetDragObject> = {
  beginDrag(props) {
    const { toggleDragState, citation } = props;
    const type = citation.get('entityType');
    toggleDragState(DragSourceType.ASSET, type);

    return { type, component: citation };
  },
  endDrag(props) {
    props.toggleDragState();
  },
  canDrag(props) {
    const { documentIdsAreBeingUploaded, citation } = props;
    const isCitationBeingUploaded = documentIdsAreBeingUploaded.has(citation.get('id'));

    return !isCitationBeingUploaded;
  },
};

const collect: DragSourceCollector<ICitationItemCollectedProps, ICitationItemOwnProps> = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
};

const CitationItem: React.FunctionComponent<ICitationItemProps> = (props) => {
  const {
    allowDelete,
    assetIdToScroll,
    citationCount,
    citation,
    connectDragPreview,
    connectDragSource,
    deleteComponent,
    documentIdsAreBeingUploaded,
    isDragging,
    isSelected,
    setAssetIdToScroll,
    addSelectedAssetPanelComponent,
    removeSelectedAssetPanelComponent,
    setSelectedCitation,
    showModal,
  } = props;

  const citationId = citation.get('id');
  const number = citation.get('number');
  const product = citation.get('product');
  const productName = product?.first();

  const { elementRef } = useScrollIntoView(citationId, assetIdToScroll, setAssetIdToScroll);
  React.useEffect(() => { connectDragPreview(getEmptyImage(), { captureDraggingState: true }); }, []);
  const { container } = useClickOutside<HTMLButtonElement>(Priority.SELECTION, {
    active: isSelected,
    onClickOutside: () => removeSelectedAssetPanelComponent(citationId),
  });

  const showCitationDetails = (): void => {
    setSelectedCitation(citation);
    showModal(ModalType.REFERENCE_CITATION_DETAILS);
  };

  const deleteCitation = (): void => {
    const entityType = citation.get('entityType');
    deleteComponent(citationId, entityType);
  };

  const onSelectHandler = (): void => {
    addSelectedAssetPanelComponent(citationId);
  };

  const citationText = citation.get('text');
  const isCitationBeingUploaded = documentIdsAreBeingUploaded.has(citationId);
  const initialText = removeTags(citationText ?? '');
  const endText = trimText(initialText);

  const handleDragStart = (event: React.DragEvent): void => {
    event.dataTransfer.setData('text/plain', citationId);
  };

  return (
    <AccessibleDiv
      className={classNames(styles.CitationItemWrapper, {
        [styles.dragging]: isDragging,
        [styles.selected]: isSelected && allowDelete,
      })}
      ref={elementRef}
      onMouseDown={onSelectHandler}
    >
      <LoadingOverlay isLocked={isCitationBeingUploaded} activeProcessType={ProcessType.DOCUMENT_UPLOADING} spinnerSize="md" />
      {
        connectDragSource(
          <button
            className={classNames(styles.CitationItem, {
              [styles.hide]: isDragging,
            })}
            ref={container}
            onDragStart={handleDragStart}
          >
            <div className={styles.content}>
              <div className={styles.citation}>
                <div className={styles.iconWrapper}>
                  <Icon type={IconType.REFERENCE_CITATION} size="sm-md" color="primary" />
                  {_.isFinite(citationCount) && allowDelete && <div className={styles.counter}>{citationCount}</div>}
                </div>
                <div className={styles.info}>
                  <div className={styles.info__primary}>
                    <span dangerouslySetInnerHTML={{ __html: initialText }} />
                    <span className={styles.info__primary__truncate} dangerouslySetInnerHTML={{ __html: endText }} />
                  </div>
                </div>
              </div>
              <div className={styles.name}>
                <span className={styles.name__primary}>{number}</span>
                {productName && <span className={styles.name__secondary}>{productName}</span>}
              </div>
            </div>
            {
              !isCitationBeingUploaded &&
              <AssetActions
                onDelete={allowDelete ? deleteCitation : (): void => { }}
                onPreviewOpen={showCitationDetails}
              />
            }
          </button>,
        )
      }
    </AccessibleDiv>
  );
};

export default DragSource<ICitationItemOwnProps, ICitationItemCollectedProps, AssetDragObject>(
  DragSourceType.ASSET,
  CitationItemDragSource,
  collect,
)(CitationItem);
