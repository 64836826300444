import classNames from 'classnames';
import React from 'react';

import Icon from 'components/Icon';
import Dropdown from 'components/Toolbar/Shared/Dropdown';
import * as Constants from 'const';
import styles from './styles.module.scss';

type Props = {
  blockLineHeight: Constants.TextLineHeightValue | undefined;
  setLineHeight: (type: Constants.TextLineHeightValue) => void;
};

const TextLineHeight: React.FunctionComponent<Props> = (props) => {
  const { setLineHeight, blockLineHeight } = props;

  return (
    <Dropdown className={styles.TextLineHeight}>
      {Constants.TEXT_LINE_HEIGHTS.map(
        ({ icon, hint, type }) => (
          <button className={classNames(styles.item, 'reset-button')} key={type} onClick={setLineHeight.bind(null, type)}>
            <Icon type={icon} size="md" color={type === blockLineHeight ? 'primary' : 'secondary'} />
            <span className={styles.hint}>{hint}</span>
          </button>
        ),
      )}
    </Dropdown>
  );
};

export default TextLineHeight;
