export enum FontPluginStyle {
  BRAND_COLOR_HEX = 'brand-color-hex', // CUSTOM property
  BRAND_COLOR_NAME = 'brand-color-name', // CUSTOM property
  BRAND_COLOR_TINT = 'brand-color-tint', // CUSTOM property
  COLOR = 'color',
  BRAND_FONT_NAME = 'brand-font-name', // CUSTOM property
  FONT_FAMILY = 'font-family',
  CHARACTER_STYLE_NAME = 'character-style-name', // CUSTOM property
  FONT_SIZE = 'font-size',
  FONT_WEIGHT = 'font-weight',
  FONT_STYLE = 'font-style',
}
