import { $wrapSelectionInMarkNode, $isMarkNode } from '@lexical/mark';
import { mergeRegister } from '@lexical/utils';
import {
  $getSelection,
  $isRangeSelection,
  LexicalEditor,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
  CLICK_COMMAND,
  KEY_DOWN_COMMAND,
} from 'lexical';
import { KeyButtonList } from 'const/Keys';
import { $getAbbreviationSelectionNode, $removeNodeAbbreviation } from './utils';

export const ABBREVIATION_CHANGE = createCommand<string | undefined>();
export const TEXT_CHANGE = createCommand<string | undefined>();

export const registerCommands = (
  editor: LexicalEditor,
  handleClick: () => void,
): () => void => {

  return mergeRegister(
    editor.registerCommand(
      ABBREVIATION_CHANGE,
      (id) => {
        const selection = $getSelection();
        if (!selection || !$isRangeSelection(selection)) {
          return false;
        }

        if (!id) {
          const node = $getAbbreviationSelectionNode(selection);
          if (node) {
            $removeNodeAbbreviation(node);
          }
        } else {
          const isBackward = selection.isBackward();
          // Wrap content in a MarkNode
          $wrapSelectionInMarkNode(selection, isBackward, id);
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    ),
    editor.registerCommand(
      CLICK_COMMAND,
      () => {

        const selection = $getSelection();
        if (!selection || !$isRangeSelection(selection)) {
          return false;
        }
        const node = $getAbbreviationSelectionNode(selection);
        if (node && selection.getTextContent().length === 0) {
          handleClick();
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    ),

    editor.registerCommand(
      KEY_DOWN_COMMAND,
      ({ key }) => {
        if ((key.length === 1
          || (
            [
              KeyButtonList.DELETE,
              KeyButtonList.BACKSPACE,
            ] as string[]).includes(key)
        )
          && key !== KeyButtonList.TAB) {
          return false;
        }
        const selection = $getSelection();
        if (!selection || !$isRangeSelection(selection)) {
          return false;
        }
        const node = $getAbbreviationSelectionNode(selection);
        if (node && $isMarkNode(node)) {
          $removeNodeAbbreviation(node);

          return true;
        } else {
          return false;
        }
      },
      COMMAND_PRIORITY_EDITOR,
    ),
  );
};

