import { $generateHtmlFromNodes } from '@lexical/html';
import { LexicalEditor } from 'lexical';

export const generateHtml = (editor: LexicalEditor | null): string => {
  if (editor) {
    return editor.read(() => {
      return $generateHtmlFromNodes(editor, null);
    });
  }

  return '';
};
