import { $isListItemNode } from '@lexical/list';
import { $getSelection, $isRangeSelection, BaseSelection } from 'lexical';

import { $isCustomListNode, CustomListNode } from 'modules/Lexical/nodes/CustomListNode';
import * as Models from 'models';

export const $getSelectionForListItem = (): BaseSelection | undefined => {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) {
    return undefined;
  }

  return $isListItemNode(selection.getNodes()[0].getParent()) ? selection : undefined;
};

export const $getBulletColorForFirstList = (colors: Models.BrandColorsList): Models.BrandColor | undefined => {
  const selection = $getSelection();

  if (!selection) {
    return undefined;
  }

  const parents = selection.getNodes()[0]?.getParents();
  const customListNode = parents?.find(item => $isCustomListNode(item)) as unknown as CustomListNode | undefined;
  const nodeColor = customListNode?.getColor();

  if (!nodeColor) {
    return undefined;
  }

  return colors.find((color) => {
    return color?.get('HEX') === nodeColor.HEX || color?.get('name') === nodeColor.name;
  }) as unknown as Models.BrandColor;
};

export const $getTopLevelCustomListFromSelection = (): CustomListNode | undefined => {
  const selection = $getSelection();
  if (selection || $isRangeSelection(selection)) {
    const parent = selection.getNodes()[0].getTopLevelElement();
    if ($isCustomListNode(parent)) {
      return parent;
    }
  }

  return undefined;
};
