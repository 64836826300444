import _ from 'lodash';
import raf from 'raf';
import React from 'react';

import Resizer from 'components/Resizer';
import { ResizerDirection } from 'const';
import * as Models from 'models';
import { getMinColumnWidth } from 'utils/relations/getMinColumnWidth';
import { isRowRelation } from 'utils/relations/isRowRelation';
import { ColumnResizerProps } from './models';

const ColumnResizer: React.FunctionComponent<ColumnResizerProps> = (props) => {
  const {
    areActionsDisabled,
    container,
    finalizeRelations,
    layoutId,
    relationIsBeingResizedId,
    relations,
    setIsResizingColumn,
    setLastEditedLayoutId,
    saveAppState,
    scale,
    toggleRelations,
  } = props;

  const rowRelation = relations.find(rel => isRowRelation(rel) && rel.get('relationIds').includes(relationIsBeingResizedId)) as Models.RowRelationMap;
  const columnIsBeingResizedIndex = rowRelation.get('relationIds').findIndex(relationId => relationId === relationIsBeingResizedId);
  const columnIsBeingResizedWidth = rowRelation.getIn(['styles', 'columnsWidth', columnIsBeingResizedIndex]);
  const columnIsBeingResizedMinWidth = getMinColumnWidth(relationIsBeingResizedId, relations);
  const rightNeighborColumnId = rowRelation.getIn(['relationIds', columnIsBeingResizedIndex + 1]);
  const rightNeighborColumnWidth = rowRelation.getIn(['styles', 'columnsWidth', columnIsBeingResizedIndex + 1]);
  const rightNeighborColumnMinWidth = getMinColumnWidth(rightNeighborColumnId, relations);
  const hintMessage = `${_.round(columnIsBeingResizedWidth)} px / ${_.round(rightNeighborColumnWidth)} px`;

  const onResize = ({ clientX }: DragEvent): number => raf(() => {
    if (!container.current) {
      return;
    }

    const { right } = container.current.getBoundingClientRect();
    const delta = _.round((right - clientX) / scale);

    const totalWidth = columnIsBeingResizedWidth + rightNeighborColumnWidth;
    // TODO: calculate appropriate minimum width when a column has nested rows
    const newColumnIsBeingResizedWidth = _.clamp(
      columnIsBeingResizedWidth - delta,
      columnIsBeingResizedMinWidth,
      totalWidth - rightNeighborColumnMinWidth,
    );

    const newRowRelation = rowRelation.updateIn(['styles', 'columnsWidth'], (columnsWidth) => {
      return columnsWidth
        .set(columnIsBeingResizedIndex, newColumnIsBeingResizedWidth)
        .set(columnIsBeingResizedIndex + 1, totalWidth - newColumnIsBeingResizedWidth);
    });

    toggleRelations(relations.set(newRowRelation.get('id'), newRowRelation));
  });

  const onResizeStart = (): void => {
    saveAppState();
    setIsResizingColumn(true);
  };

  const onResizeStop = (): void => {
    setLastEditedLayoutId(layoutId);
    finalizeRelations(relations);
    setIsResizingColumn(false);
  };

  return (
    <Resizer
      allowVisibilityChange={!areActionsDisabled}
      scale={scale}
      direction={ResizerDirection.VERTICAL}
      hintMessage={hintMessage}
      onResize={onResize}
      onResizeStart={onResizeStart}
      onResizeStop={onResizeStop}
    />
  );
};

export default ColumnResizer;
