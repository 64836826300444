import { undoRedoKeyBindings } from 'modules/draftjs';
import React from 'react';
import Button from 'components/Button';
import { IconType } from 'components/Icon/constants';
import { EditorKeyBindingCommand, HandleValue } from 'const';
import { useIsOpenToolbar } from 'hooks/useIsOpenToolbar';
import { IAction } from 'models';
import { eventEmitter, EMITTER_EVENTS } from 'utils/eventEmitter';
import { UndoRedoControlProps } from './models';
import styles from './styles.module.scss';

const UndoRedoControl: React.FunctionComponent<UndoRedoControlProps> = ({
  redo,
  undo,
  isRedoDisabled,
  isUndoDisabled,
  stopHandlingReusableLayoutsEditing = (): null => null,
  startHandlingReusableLayoutsEditing = (): null => null,
}) => {
  const undoWithLayoutEditing = (): IAction | void => {
    stopHandlingReusableLayoutsEditing();
    const result = undo();
    startHandlingReusableLayoutsEditing();

    return result;
  };
  const isOpenToolbar = useIsOpenToolbar();

  React.useEffect(() => {
    if (isOpenToolbar) {
      return () => {};
    }

    const handleKeyCommand = (command: string | null): HandleValue => {
      if (command === EditorKeyBindingCommand.UNDO && !isUndoDisabled) {
        undoWithLayoutEditing();

        return HandleValue.HANDLED;
      }
      if (command === EditorKeyBindingCommand.REDO && !isRedoDisabled) {
        redo();

        return HandleValue.HANDLED;
      }

      return HandleValue.NOT_HANDLED;
    };

    const undoRedoGlobalHandler = (e: KeyboardEvent): HandleValue => handleKeyCommand(undoRedoKeyBindings(e));

    eventEmitter.addListener(EMITTER_EVENTS.BODY_KEYDOWN, undoRedoGlobalHandler);

    return () => {
      eventEmitter.removeListener(EMITTER_EVENTS.BODY_KEYDOWN, undoRedoGlobalHandler);
    };
  }, [isOpenToolbar, isUndoDisabled, isRedoDisabled]);


  return (
    <div className={styles.UndoRedoControl}>
      <Button
        icon={IconType.UNDO}
        size="md"
        disabled={isUndoDisabled}
        onClick={undoWithLayoutEditing}
        highlightOnHover={true}
      />
      <Button
        icon={IconType.REDO}
        size="md"
        disabled={isRedoDisabled}
        onClick={redo}
        highlightOnHover={true}
      />
    </div>
  );
};

export default UndoRedoControl;
