import { eventChannel } from 'redux-saga';
import { call, put, take } from 'redux-saga/effects';

import { Hotkeys } from 'services/hotkeys';

const hotkeysChannel = () => eventChannel((emitter) => {
  Hotkeys.init(emitter);

  return Hotkeys.reset;
});

export function* watchHotkeys(): Generator {
  const channel = yield call(hotkeysChannel);

  while (true) {
    const action = yield take(channel);

    if (action) {
      yield put(action);
    }
  }
}
