import { ElementFormatType } from 'lexical';
import { BulletListType, LexicalFieldDescribes } from './types';

export const lexicalImportBase = {
  editorState: {
    root: {
      children: {},
      direction: 'ltr',
      indent: 0,
      type: 'root',
      version: 1,
    },
    lastSaved: 1720700322398,
    source: 'Playground',
    version: '0.16.1',
  },
};

export const lexicalListNodeBase = {
  direction: LexicalFieldDescribes.LTR,
  indent: 0,
  type: BulletListType.LIST,
  version: 1,
  listType: BulletListType.BULLET,
  start: 1,
  tag: BulletListType.UL_TAG,
  format: '' as ElementFormatType,
  ids: [],
};

export const lexicalListItemNodeBase = {
  direction: LexicalFieldDescribes.LTR,
  type: 'listitem',
  version: 1,
  format: '' as ElementFormatType,
  textFormat: 0,
  indent: 0,
  ids: [],
};
