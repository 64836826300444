import { LinkNode, SerializedLinkNode } from '@lexical/link';
import { SerializedLexicalNode } from 'lexical';
import { DraftjsEntityData, LexicalFieldDescribes } from '../types';

export function createLinkNode(
  child: SerializedLexicalNode,
  data: DraftjsEntityData | undefined,
): SerializedLinkNode {
  return {
    type: LinkNode.getType(),
    rel: null,
    target: '_blank',
    title: null,
    url: data?.url ?? '',
    children: [child],
    direction: LexicalFieldDescribes.LTR,
    format: '',
    version: 1,
    indent: 0,
  };
}
