import ChevronIcon from 'assets/images/ChevronRight';
import CloseIcon from 'assets/images/CloseIcon';
import classnames from 'classnames';
import React from 'react';

import styleVariables from 'styles/variables.scss';
import { toPx } from 'utils/toPx';
import { IBalanceLineProps, IBalanceLineState } from './models';
import styles from './styles.module.scss';

const DEFAULT_BALANCED_LINE_TOP = 800;

export default class BalanceLine extends React.PureComponent<IBalanceLineProps, IBalanceLineState> {
  static readonly defaultProps: DefaultProps<IBalanceLineProps> = {
    top: DEFAULT_BALANCED_LINE_TOP,
  };

  readonly state: IBalanceLineState = {
    isExpanded: true,
  };

  private readonly onToggleClick = (): void => {
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
  };

  private readonly renderHint = (): JSX.Element => {
    const { isExpanded } = this.state;
    const { top } = this.props;

    const hintMessage = <span className={styles.hint}>{toPx(top)}</span>;
    const hintIcon = isExpanded ? <CloseIcon /> : <ChevronIcon />;

    return (
      <div className={styles.hintContainer}>
        <span className={styles.toggle} onClick={this.onToggleClick}>
          {hintIcon}
        </span>
        {isExpanded && hintMessage}
      </div>
    );
  };

  render(): JSX.Element {
    const { top, scale } = this.props;
    const { isExpanded } = this.state;
    const lineClassName = classnames(styles.line, {
      [styles.expanded]: isExpanded,
    });

    return (
      <div
        className={styles.BalanceLine}
        style={{ top: `calc(${styleVariables.contentWrapperTopPadding} + ${toPx(top * scale)})` }}
      >
        <div className={lineClassName} />
        {this.renderHint()}
      </div>
    );
  }
}
