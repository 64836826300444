import React from 'react';
import { DragSource, DragSourceCollector, DragSourceSpec } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { useScrollIntoView } from 'components/AssetList/hooks';
import ImageBox from 'components/ImageBox';
import { DragSourceType, EntityType, ModalType } from 'const';
import { Priority, useClickOutside } from 'hooks/useClickOutside';
import { AssetDragObject, Image } from 'models';
import * as Models from './models';
import styles from './styles.module.scss';

const AssetItemDragSource: DragSourceSpec<Models.IImageListItemProps, AssetDragObject> = {
  beginDrag(props) {
    const { toggleDragState, component } = props;
    const type = EntityType.IMAGE;
    toggleDragState(DragSourceType.ASSET, type);

    return {
      type,
      component,
    };
  },

  endDrag(props) {
    props.toggleDragState();
  },

  canDrag(props) {
    const { component, documentIdsAreBeingUploaded } = props;
    const isImageBeingUploaded = documentIdsAreBeingUploaded.has(component.get('id'));
    const internalImageInfo = component.get('_internalInfo');
    const imageSource = internalImageInfo?.get('source');

    return !isImageBeingUploaded && !!imageSource;
  },
};

const collect: DragSourceCollector<Models.IImageListItemCollectedProps, Models.IImageListItemAllOwnProps> = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
};

const ImageListItem: React.FunctionComponent<Models.IImageListItemProps> = (props) => {
  const {
    allowChange,
    allowDelete,
    assetIdToScroll,
    component,
    connectDragPreview,
    connectDragSource,
    deleteComponent,
    documentIdsAreBeingUploaded,
    imageCount,
    isDragging,
    isSelected,
    setAssetIdToScroll,
    addSelectedAssetPanelComponent,
    removeSelectedAssetPanelComponent,
    showModal,
  } = props;

  const componentId = component.get('id');
  const { elementRef } = useScrollIntoView(componentId, assetIdToScroll, setAssetIdToScroll);
  React.useEffect(() => { connectDragPreview(getEmptyImage(), { captureDraggingState: true }); }, []);
  const { container } = useClickOutside<HTMLDivElement>(Priority.SELECTION, {
    active: isSelected,
    onClickOutside: () => removeSelectedAssetPanelComponent(componentId),
  });

  const showImageDetails = (): void => {
    showModal(ModalType.IMAGE_DETAILS, { document: component.toJS() as Image, allowChange });
  };

  const deleteImage = (): void => {
    const entityType = component.get('entityType');
    deleteComponent(componentId, entityType);
  };

  const onSelectHandler = (): void => {
    addSelectedAssetPanelComponent(componentId);
  };

  const isImageBeingUploaded = documentIdsAreBeingUploaded.has(componentId);

  return connectDragSource((
    <div className={styles.ImageListItem} ref={elementRef} onMouseDown={allowChange ? onSelectHandler : (): void => {}}>
      <ImageBox
        container={container}
        deleteImage={allowDelete ? deleteImage : undefined}
        image={component}
        imageCount={allowDelete ? imageCount : undefined}
        isDragging={isDragging}
        isImageBeingUploaded={isImageBeingUploaded}
        isSelected={allowChange && isSelected}
        showImageDetails={showImageDetails}
      />
    </div>
  ));
};

export default DragSource<Models.IImageListItemOwnProps, Models.IImageListItemCollectedProps, AssetDragObject>(
  DragSourceType.ASSET,
  AssetItemDragSource,
  collect,
)(ImageListItem);
