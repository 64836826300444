import Draft from 'draft-js';
import { useState } from 'react';
import { compositeDecorator } from '../decorators';

type Setter = (prev: Draft.EditorState) => Draft.EditorState;

export function applyDraftjsEditorDecorator(
  state: Draft.EditorState,
): Draft.EditorState {
  return Draft.EditorState.set(state, { decorator: compositeDecorator });
}

export function flowWithApplyDraftjsEditorDecorator(arg: Draft.EditorState | Setter): Setter {
  return (prev: Draft.EditorState) => applyDraftjsEditorDecorator(
    typeof arg === 'function' ? arg(prev) : arg,
  );
}

export type DraftjsEditorStateSetter = (
  arg: Draft.EditorState | ((prev: Draft.EditorState) => Draft.EditorState)
) => void;

export type DraftjsEditorStateHook = {
  editorState: Draft.EditorState;
  setEditorState: DraftjsEditorStateSetter;
};

export function useDraftjsEditorState(
  onInit: () => Draft.EditorState,
  featureDecorators: boolean,
): DraftjsEditorStateHook {
  const [editorState, setEditorState] = useState(
    () => featureDecorators ? applyDraftjsEditorDecorator(onInit()) : onInit(),
  );

  return { editorState, setEditorState };
}
