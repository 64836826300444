import { MarkNode, SerializedMarkNode } from '@lexical/mark';
import { SerializedLexicalNode } from 'lexical';
import { DraftjsEntityData, LexicalFieldDescribes } from '../types';

export function createMarkNode(
  child: SerializedLexicalNode,
  data: DraftjsEntityData | undefined,
): SerializedMarkNode {
  return {
    type: MarkNode.getType(),
    ids: data?.id ? [data?.id] : [],
    children: [child],
    direction: LexicalFieldDescribes.LTR,
    format: '',
    version: 1,
    indent: 0,
  };
}
