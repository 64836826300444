import { mergeRegister } from '@lexical/utils';
import {
  CAN_REDO_COMMAND, CAN_UNDO_COMMAND, REDO_COMMAND, UNDO_COMMAND,
  COMMAND_PRIORITY_NORMAL,
  LexicalEditor,
} from 'lexical';
import { useCallback, useEffect, useState } from 'react';

type Hook = {
  undo: () => void;
  redo: () => void;
  isRedoDisabled: boolean;
  isUndoDisabled: boolean;
};

type State = {
  isRedoDisabled: boolean;
  isUndoDisabled: boolean;
};

export function useLexicalUndo(editor: LexicalEditor | null): Hook {
  const [state, setState] = useState<State>({ isRedoDisabled: true, isUndoDisabled: true });

  const undo = useCallback(() => editor?.dispatchCommand(UNDO_COMMAND, undefined), [editor]);
  const redo = useCallback(() => editor?.dispatchCommand(REDO_COMMAND, undefined), [editor]);

  useEffect(() => {
    if (!editor) {
      if (!state.isUndoDisabled || !state.isRedoDisabled) {
        setState({ isUndoDisabled: true, isRedoDisabled: true });
      }

      return undefined;
    }

    return mergeRegister(
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (value) => {
          setState(prev => ({ ...prev, isUndoDisabled: !value }));

          return false;
        },
        COMMAND_PRIORITY_NORMAL,
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (value) => {
          setState(prev => ({ ...prev, isRedoDisabled: !value }));

          return false;
        },
        COMMAND_PRIORITY_NORMAL,
      ),
    );
  }, [editor]);

  return { ...state, undo, redo };
}
