import PluginEditor from '@draft-js-plugins/editor';
import { LexicalEditor } from 'lexical';
import { LexicalOnChangeCTA } from 'modules/Lexical/hooks/useCallToActionEditor';
import React, { createContext, ReactNode, useContext } from 'react';
import { IList } from 'typings/DeepIMap';
import { AssetAlignment, BrandColor, BrandFont } from 'models';
import { UndoHook } from '../hooks/useUndo';

type ButtonContextType = {
  editorRef: React.RefObject<PluginEditor>;
  lexicalEditorRef: React.RefObject<LexicalEditor>;
  lexicalHookOnChange: LexicalOnChangeCTA;
  id: string;
  cssButton: string;
  editMode: boolean;
  editorWrap: React.RefObject<HTMLDivElement>;
  editorState: Draft.EditorState;
  onEditorChange: (editorState: Draft.EditorState) => void;
  returnFocusToEditor: () => void;
  styles: React.CSSProperties;
  brandProps: {
    colors: IList<BrandColor>;
    fonts: IList<BrandFont>;
  };
  undoHook: UndoHook;
};

type ResizeContextType = {
  alignment: AssetAlignment;
  container: HTMLDivElement | undefined;
  startResizing: (currentWidth: number, currentHeight: number) => void;
  finishResizing: (actualWidth: number, actualHeight: number) => void;
  onResize: (newHeight: number) => void;
  width: number;
  height: number;
  minHeight: number;
};

const ButtonContext = createContext<ButtonContextType | null>(null);
const ResizeContext = createContext<ResizeContextType | null>(null);


export const useButtonContext = (): ButtonContextType => {
  const context = useContext(ButtonContext);

  if (!context) {
    throw new Error('useButtonContext must be used within a ButtonProvider');
  }

  return context;
};

export const useResizeContext = (): ResizeContextType => {
  const context = useContext(ResizeContext);

  if (!context) {
    throw new Error('useResizeContext must be used within a ResizeProvider');
  }

  return context;
};

interface ButtonProviderProps {
  value: ButtonContextType;
  children: ReactNode;
}

interface ResizeProviderProps {
  value: ResizeContextType;
  children: ReactNode;
}

export const ButtonProvider: React.FC<ButtonProviderProps> = ({ children, value }) => {
  return <ButtonContext.Provider value={value}>{children}</ButtonContext.Provider>;
};


export const ResizeProvider: React.FC<ResizeProviderProps> = ({ children, value }) => {
  return <ResizeContext.Provider value={value}>{children}</ResizeContext.Provider>;
};
