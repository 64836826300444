import { MarkNode } from '@lexical/mark';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { LexicalEditor, ParagraphNode, TextNode } from 'lexical';
import React, { forwardRef } from 'react';
import { BrandProps } from 'components/ArtboardAssets/Text/hooks/useBrandProps';
import * as Constants from 'const';
import * as Models from 'models';
import { AbbreviationPlugin } from '../../Abbreviations/Lexical/AbbreviationPlugin';
import { BasePropsPlugin } from '../../Lexical/Plugins/BasePropsPlugin';
import { DraftjsRawContentPlugin } from '../../Lexical/Plugins/DraftjsContentPlugin';
import { EditModePlugin } from '../../Lexical/Plugins/EditModePlugin';
import { EditorRefPlugin } from '../../Lexical/Plugins/EditorRefPlugin';
import { FontPlugin } from '../../Lexical/Plugins/FontPlugin';
import { mainTheme } from '../../Lexical/Theme/mainTheme';
import { LexicalOnChangeCTA } from '../hooks/useCallToActionEditor';
import { CustomParagraphNode } from '../nodes/CustomParagraphNode';
import { CustomTextNode } from '../nodes/CustomTextNode';

const TEXT_LINE_HEIGHT = Constants.TextLineHeightValue.ONE_POINT_FIFTEEN;

const editorConfig = {
  namespace: 'call-to-action-editor',
  editable: false,
  theme: mainTheme,
  onError: (error): void => {
    throw error;
  },
  nodes: [
    CustomTextNode,
    {
      replace: TextNode,
      with: (node: TextNode): CustomTextNode => new CustomTextNode(node.__text),
    },
    CustomParagraphNode,
    {
      replace: ParagraphNode,
      with: (): CustomParagraphNode => new CustomParagraphNode(TEXT_LINE_HEIGHT),
    },
    MarkNode,
  ],
};

type Props = {
  onChange: LexicalOnChangeCTA;
  editMode: boolean;
  draftContent?: Draft.ContentState;
  brandStyle: Models.BrandStyleMap | undefined;
  brandProps: Omit<BrandProps, 'brandStyles' >;
};

export const CallToActionEditor = forwardRef<LexicalEditor, Props>((props, ref) => {
  const {
    editMode, draftContent,
    brandStyle,
    brandProps,
    onChange,
  } = props;

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className="editor-container">
        <RichTextPlugin
          contentEditable={<ContentEditable className="editor-input" />}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <HistoryPlugin />
        <AutoFocusPlugin />
        <DraftjsRawContentPlugin
          draftjsContent={draftContent}
          editMode={editMode}
          colors={brandProps.colors}
          fonts={brandProps.fonts}
        />
        <EditorRefPlugin ref={ref} />
        <EditModePlugin editMode={editMode} />
        <BasePropsPlugin onChange={onChange.baseProps} />
        <FontPlugin
          onChange={onChange.font}
          brandStyle={brandStyle}
          colors={brandProps.colors}
          fonts={brandProps.fonts}
        />
        <AbbreviationPlugin onChange={onChange.abbreviations} />
      </div>
    </LexicalComposer>
  );
});
