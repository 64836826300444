
import React from 'react';

import List from 'components/AssetList';
import Header from 'components/AssetList/Header';
import { useToggle } from 'components/AssetList/hooks';
import { EntityType } from 'const';
import LayoutListItem from 'containers/LayoutListItem';
import { ReusableLayoutMap } from 'models';
import { intlGet } from 'utils/intlGet';
import { LayoutListProps } from './models';
import styles from './styles.module.scss';

const LayoutList: React.FunctionComponent<LayoutListProps> = (props) => {
  const {
    isExpand,
    reusableLayouts,
    showUpdatedReusableLayouts,
    storyCardId,
    toggleExpand,
    toggleUpdatedDocumentsDisplaying,
    updatedInPromoMats,
  } = props;

  const {
    expanded,
    toggle,
  } = useToggle({
    toggleUpdatedDocumentsDisplaying,
    showUpdatedDocuments: showUpdatedReusableLayouts,
    entityType: EntityType.LAYOUT,
    toggle: toggleExpand,
    isExpand,
  });

  const renderHint = (): JSX.Element => (
    <div className={styles.hint}>
      <div>{intlGet('Assets', 'ReusableLayouts.Hint.Title')}</div>
      <div>{intlGet('Assets', 'ReusableLayouts.Hint.Message')}</div>
    </div>
  );

  return (
    <div className={styles.ReusableLayoutList} data-title="reusable-layouts">
      {
        !storyCardId &&
        <Header
          title={intlGet('Assets', 'ReusableLayouts', { count: reusableLayouts.size })}
          showUpdatesHint={updatedInPromoMats && showUpdatedReusableLayouts}
          onClick={toggle}
          expanded={expanded}
        />
      }
      {
        (expanded || storyCardId) &&
          <List
            border={!storyCardId}
            elements={reusableLayouts}
            subheader={!storyCardId && reusableLayouts && reusableLayouts.size === 0 && renderHint()}
            renderElement={(reusableLayout: ReusableLayoutMap): React.ReactNode => (
              <LayoutListItem key={reusableLayout.get('id')} document={reusableLayout} storyCardId={storyCardId} />
            )}
          />
      }
    </div >
  );
};

export default LayoutList;
