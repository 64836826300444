import React, { useEffect, useState } from 'react';

import { useResize } from 'components/ArtboardLayout/hooks/useResize';
import { useLayoutDragHotspot } from 'components/Section/hooks';
import { UseLayoutDragHotspotProps } from 'components/Section/models';
import { ProjectsConfig } from 'const';
import { Action } from 'containers/Layouts/models';
import { getActiveLayer } from 'hooks/useActiveLayer';
import { useImagesLoadingObserver } from 'hooks/useImagesLoadingObserver';
import * as Models from 'models';
import { isImage } from 'utils/entityType';
import { isLayoutScrollable } from 'utils/layouts/isLayoutScrollable';
import { isRegularRelation } from 'utils/relations/isRegularRelation';
import { sum } from 'utils/sum';
import { allowedToDropItems } from './dnd';
import { GroupLayoutProps } from './models';

export const useGroupLayout = (props: GroupLayoutProps) => {
  const {
    documents,
    relationsByRegularLayoutId,
    dragItemType,
    getLayoutsRefs,
    isLayoutBeingUploaded,
    isOver,
    layout: groupLayout,
    layouts,
    resetLayoutHeightSilently,
    setLayout,
    ssi,
    projectType,
  } = props;
  const activeLayer = getActiveLayer();
  const layoutId = groupLayout.get('id');
  const layoutIds = groupLayout.get('layoutIds');
  const childLayouts = layoutIds.map(id => layouts.get(id) as Models.LayoutMap);
  const isGroupLayoutWithOneChild = childLayouts.size === 1;
  const isOverByAllowedItem = isOver && allowedToDropItems.includes(dragItemType);
  const layoutDocument = documents.get(groupLayout.get('documentId')) as Models.GroupLayoutDocumentMap;
  const layoutDocumentName = layoutDocument && layoutDocument.get('name');
  const layoutDocumentNumber = layoutDocument && layoutDocument.get('number');
  const imagesDownloaded = childLayouts
    .flatMap(layout => relationsByRegularLayoutId.get(layout.get('id')).toList())
    .filter<Models.LayeredRegularRelationMap>(isRegularRelation)
    .map(relation => documents.get(relation.getIn(['documentId', activeLayer])))
    .filter<Models.ImageMap>(isImage)
    .every(image => !!image.getIn(['_internalInfo', 'source']));

  const container = React.useRef<HTMLDivElement>(null);
  const pinnedLayoutRef = React.useRef<HTMLDivElement>(null);
  const unpinnedLayoutsContainer = React.useRef<HTMLDivElement>(null);

  const { allowScrollableLayout } = ProjectsConfig[projectType];
  const firstLayout = childLayouts.first<Models.LayoutMap>();
  const pinnedLayout = allowScrollableLayout && !isLayoutScrollable(firstLayout) ? firstLayout : null;

  const calcMaxHeight = (): number => pinnedLayout
    ? pinnedLayoutRef.current.offsetHeight + unpinnedLayoutsContainer.current.scrollHeight
    : container.current.scrollHeight;

  const [height, toggleHeight] = useState(groupLayout.getIn(['styles', 'height']));

  useEffect(
    () => toggleHeight(groupLayout.getIn(['styles', 'height'])),
    [groupLayout],
  );

  const { onResize } = useResize({ calcMaxHeight, height, toggleHeight, getClientRect: () => container.current.getBoundingClientRect() });
  const { isDraggingLayout, getLayoutDragHotspotPosition } = useLayoutDragHotspot(props as UseLayoutDragHotspotProps, layoutIds, layoutId);

  const onResizeEnd = (): Action.ISetLayout => setLayout(groupLayout.setIn(['styles', 'height'], height));

  const [imagesLoaded, setImagesLoaded] = React.useState(false);
  const onImagesLoaded = (): void => setImagesLoaded(true);

  const [fontsLoaded, setFontsLoaded] = React.useState(false);
  React.useEffect(() => { document.fonts.ready.then(() => setFontsLoaded(true)); }, []);

  React.useEffect(
    () => {
      if (ssi || !imagesLoaded || !fontsLoaded) {
        return;
      }

      if (height) {
        const domHeight = calcMaxHeight();

        if (height > domHeight) {
          resetLayoutHeightSilently(layoutId, domHeight);
        }

        return;
      }

      const layoutsRefs = getLayoutsRefs();
      const layoutsHeight = childLayouts.map(layout => layout.getIn(['styles', 'height']) ?? layoutsRefs.get(layout.get('id')).offsetHeight);
      setLayout(groupLayout.setIn(['styles', 'height'], sum(layoutsHeight)));
    },
    [imagesLoaded, fontsLoaded],
  );

  const onDragOver = (event: React.MouseEvent): void => {
    if (isLayoutBeingUploaded || !isOverByAllowedItem || ssi) {
      return;
    }

    getLayoutDragHotspotPosition(event.clientY);
  };

  useImagesLoadingObserver({ container, onLoaded: () => {
    if (!imagesDownloaded) {
      return;
    }

    onImagesLoaded();
  } });

  return {
    pinnedLayout,
    childLayouts,
    isGroupLayoutWithOneChild,
    isOverByAllowedItem,
    layoutDocumentName,
    layoutDocumentNumber,
    layoutId,
    layoutIds,
    container,
    pinnedLayoutRef,
    unpinnedLayoutsContainer,

    height,
    onResize,
    onResizeEnd,

    isDraggingLayout,
    onDragOver,

    onImagesLoaded,
    imagesDownloaded,
  };
};
