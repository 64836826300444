import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import Draft from 'draft-js';
import { EditorState, LexicalEditor } from 'lexical';
import { useEffect, useMemo, useRef } from 'react';
import * as Models from 'models';
import { lexicalRawConverter, SerializedDocument } from '../LexicalRawConverter/LexicalRawConverter';

export function editorStateFromSerializedDocument(
  editor: LexicalEditor,
  maybeStringifiedDocument: SerializedDocument,
): EditorState {
  const json: SerializedDocument =
    typeof maybeStringifiedDocument === 'string'
      ? JSON.parse(maybeStringifiedDocument)
      : maybeStringifiedDocument;

  return editor.parseEditorState(json.editorState);
}

type Props = {
  editMode: boolean;
  draftjsContent?: Draft.ContentState;
  colors: Models.BrandColorsList;
  fonts: Models.BrandFontsList;
};

export function DraftjsRawContentPlugin(props: Props): null {
  const { editMode, draftjsContent, colors, fonts } = props;
  const [editor] = useLexicalComposerContext();

  const rawContent = useMemo(() => {
    return draftjsContent && Draft.convertToRaw(draftjsContent);
  }, [draftjsContent]);

  const didMountRef = useRef(false);

  useEffect(() => {

    if (!rawContent) {
      return;
    }

    if (didMountRef.current && editMode) {
      return;
    }

    const formatedData = lexicalRawConverter(rawContent, colors, fonts);
    editor.setEditorState(
      editorStateFromSerializedDocument(editor, formatedData as SerializedDocument),
    );
  }, [rawContent, editMode, colors, fonts]);

  useEffect(() => {
    didMountRef.current = true;
  }, []);

  return null;
}
