import _ from 'lodash';
import { useCallback } from 'react';
import * as Models from 'models';
import { StylesHook } from './useStyles';

export type ResetWidth = (paddingValue: Models.PaddingMap, borderValue?: Models.BorderMap, cb?: (maxWidth: number) => void) => void;

export const useResetWidth = (stylesHook: StylesHook): ResetWidth => {
  const { styles, setters: stylesSetters, getMaxWidth } = stylesHook;
  const width = styles.width;

  return useCallback((
    paddingValue: Models.PaddingMap,
    borderValue?: Models.BorderMap,
    cb?: (maxWidth: number) => void,
  ): void => {
    const maxWidth = getMaxWidth(paddingValue, borderValue);
    if (width > maxWidth) {
      if (_.isFunction(cb)) {
        cb(maxWidth);
      } else {
        stylesSetters.width(maxWidth);
      }
    }
  }, [stylesSetters, width]);
};
