export const WHITE_SPACE_PROP = 'white-space';
export const NO_WRAP_VALUE = 'nowrap';

export const NO_WRAP_STYLES_OBJECT = {
  display: 'inline-block',
  [WHITE_SPACE_PROP]: NO_WRAP_VALUE,
};

export const RESET_NO_WRAP_STYLES_OBJECT = {
  display: null,
  [WHITE_SPACE_PROP]: null,
};
