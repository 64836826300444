import { LexicalNode, SerializedTextNode, TextNode } from 'lexical';
import { BrandColorLike } from 'modules/BrandDefinition';
import { FontPluginStyle } from '../Plugins/FontPlugin/style';
import { brandColorToStyle } from '../Plugins/FontPlugin/utils';

// replaces Models.FontFamily
type CustomTextFont = {
  brandFontName: string;
  characterStyleName?: string;
};

export class CustomTextNode extends TextNode {
  static getType(): string {
    return 'custom-text';
  }

  static fontToStyleArray(value: CustomTextFont | undefined): Record<string, string | null> {
    return {
      [FontPluginStyle.BRAND_FONT_NAME]: value?.brandFontName ?? null,
      [FontPluginStyle.CHARACTER_STYLE_NAME]: value?.characterStyleName ?? null,
    };
  }

  protected readStyleValues<T extends string[]>(
    ...keys: T
  ): { [key in T[number]]: string | undefined } {
    const styles = (this.__style ?? '').split(';');

    const entries = keys.map((key) => {
      const prefix = `${key}:`;
      const styleEntry = styles.findLast(entry => entry.trimStart().startsWith(prefix));
      if (!styleEntry) {
        return [key, undefined];
      }
      const value = styleEntry.trim().substring(prefix.length).trim();

      return [key, value];
    });

    return Object.fromEntries(entries);
  }

  protected writeStyleValues(values: Record<string, string | null>): void {
    const prefixes = Object.keys(values).map(key => `${key}:`);
    const clearedStyles = (this.__style ?? '').split(';')
      .filter(entry => !prefixes.some(prefix => entry.trimStart().startsWith(prefix)));
    const newStyles = Object.entries(values)
      .filter(([,value]) => !!value)
      .map(([key, value]) => `${key}: ${value as string}`);
    this.__style = [...clearedStyles, ...newStyles].join('; ');
  }

  static clone(node: CustomTextNode): CustomTextNode {
    return new CustomTextNode(node.__text, node.__key);
  }

  get __color(): BrandColorLike | undefined {
    const {
      [FontPluginStyle.BRAND_COLOR_HEX]: HEX,
      [FontPluginStyle.BRAND_COLOR_NAME]: name,
      [FontPluginStyle.BRAND_COLOR_TINT]: tint,
    } = this.readStyleValues(
      FontPluginStyle.BRAND_COLOR_HEX,
      FontPluginStyle.BRAND_COLOR_NAME,
      FontPluginStyle.BRAND_COLOR_TINT,
    );
    if (!HEX) {
      return undefined;
    }

    return { HEX, name, tint: tint ? Number(tint) : undefined };
  }

  set __color(value: BrandColorLike | undefined) {
    this.writeStyleValues(brandColorToStyle(value));
  }

  setColor(color: BrandColorLike | undefined): void {
    this.getWritable().__color = color;
  }

  getColor(): BrandColorLike | undefined {
    return this.getLatest().__color;
  }

  get __font(): CustomTextFont | undefined {
    const {
      [FontPluginStyle.BRAND_FONT_NAME]: brandFontName,
      [FontPluginStyle.CHARACTER_STYLE_NAME]: characterStyleName,
    } = this.readStyleValues(FontPluginStyle.BRAND_FONT_NAME, FontPluginStyle.CHARACTER_STYLE_NAME);
    if (!brandFontName) {
      return undefined;
    }

    return { brandFontName, characterStyleName };
  }

  set __font(value: CustomTextFont | undefined) {
    this.writeStyleValues(CustomTextNode.fontToStyleArray(value));
  }

  setFont(value: CustomTextFont | undefined): void {
    this.getWritable().__font = value;
  }

  getFont(): CustomTextFont | undefined {
    return this.getLatest().__font;
  }

  exportJSON(): SerializedTextNode {
    const data = super.exportJSON();
    data.type = CustomTextNode.getType();

    return data;
  }

  static importJSON(serializedNode: SerializedTextNode): TextNode {
    return super.importJSON(serializedNode);
  }
}

export function $isCustomTextNode(node: LexicalNode | null | undefined): node is CustomTextNode {
  return node instanceof CustomTextNode;
}
