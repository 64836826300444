import classNames from 'classnames';
import React from 'react';

import { IconType } from './constants';
import icons from './icons';
import { IconProps, IconState } from './models';
import styles from './styles.module.scss';

export default class Icon extends React.PureComponent<IconProps, IconState> {
  render(): JSX.Element {
    const {
      type,
      size,
      color,
      disableHover,
      isDisabled,
      disableColor,
      onClick,
      hoverTooltipText,
      hoverColor,
      className,
      iconTooltipClassName,
      ...restProps
    } = this.props;

    let SelectedIcon = icons[type];
    if (!SelectedIcon) {
      // eslint-disable-next-line no-console
      console.error(`Icon (${type}) does not exist. Please check the project configuration.`);
      SelectedIcon = icons[IconType.DESKTOP_SCREEN];
    }

    return (
      <span
        className={classNames(styles.icon_wrapper, iconTooltipClassName, {
          [styles.icon_tooltip]: !!hoverTooltipText,
          [className]: !!className,
        })}
        data-title={type}
        data-tooltip={hoverTooltipText}
        {...restProps}
      >
        <span
          className={classNames(styles.icon, {
            [styles[`color_${color}`]]: !!color,
            [styles[`size_${size}`]]: !!size,
            [styles.clickable]: !!onClick && !isDisabled,
            [styles.disabled]: isDisabled,
            [styles[`color_${hoverColor}`]]: !disableHover && !!hoverColor,
          })}
          onClick={isDisabled ? (): void => {} : onClick}
          style={{
            ...isDisabled && disableColor && { color: disableColor },
          }}
        >
          <SelectedIcon />
        </span>
      </span>
    );
  }
}
