import classNames from 'classnames';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { LexicalEditor } from 'lexical';
import { ReferencesEditor } from 'modules/draftjs';
import { useDraftjsReferencesEditor } from 'modules/draftjs/hooks/useDraftjsReferencesEditor';
import { LexicalReferencesEditor } from 'modules/Lexical/components/LexicalReferencesEditor';
import { generateHtml } from 'modules/Lexical/editorUtils';
import { useLexicalReferencesEditor } from 'modules/Lexical/hooks/useLexicalReferencesEditor';
import { ReferencesEditorHook } from 'modules/References/models/ReferencesEditorHook';
import React from 'react';
import { useEditorToggle } from 'components/ArtboardAssets/useEditorToggle';
import Toolbar from 'components/Toolbar/ReferenceCitation';
import { cleanUpHtml } from 'utils/cleanUpHtml';
import * as editorUtils from 'utils/editor';
import ReferenceCitationActions from '../ReferenceCitationActions';
import { IReferenceCitationEditorProps } from './models';
import styles from './styles.module.scss';

// TODO: rewrite it using PlainTextEditor
const ReferenceCitationEditor: React.FunctionComponent<IReferenceCitationEditorProps> = (props) => {
  const {
    deleteReferenceFromText,
    layoutId,
    reference,
    updateReference,
  } = props;

  const referenceHtml = reference ? reference.get('text') : '';
  const initialDraftjsEditorState = React.useRef<EditorState>(editorUtils.htmlToEditorState(referenceHtml));

  const { isLexicalMode, isDraftjsMode } = useEditorToggle();

  const draftjsHook = useDraftjsReferencesEditor(() => initialDraftjsEditorState.current);
  const lexicalHook = useLexicalReferencesEditor(referenceHtml);

  const editorHook: ReferencesEditorHook = isLexicalMode ? lexicalHook : draftjsHook;

  // Current DraftJs Editor State
  const currentDraftjsEditorState = React.useRef<EditorState>();
  currentDraftjsEditorState.current = draftjsHook.editorState;

  // Current Lexical Editor
  const lexicalEditorRef = React.useRef<LexicalEditor | null>(null);
  lexicalEditorRef.current = lexicalHook.ref.current;

  const deleteReference = (): void => {
    const id = reference.get('id');
    deleteReferenceFromText(id);
  };

  React.useEffect(
    () => {
      editorHook.focusEditor();

      return () => {
        if (isDraftjsMode && currentDraftjsEditorState.current) {
          const options = editorUtils.getReferenceCitationStateToHTMLOptions();
          const html = stateToHTML(currentDraftjsEditorState.current.getCurrentContent(), options);
          const originalHtml = stateToHTML(initialDraftjsEditorState.current.getCurrentContent(), options);
          const isEmpty = currentDraftjsEditorState.current.getCurrentContent().getPlainText().trim().length === 0;

          if (isEmpty) {
            deleteReference();
          } else if (html !== originalHtml) {
            updateReference(reference.get('id'), html);
          }
        }

        if (isLexicalMode) {
          const text = generateHtml(lexicalEditorRef.current);
          if (!text) {
            deleteReference();
          } else {
            const html = cleanUpHtml(text);
            if (html !== referenceHtml) {
              updateReference(reference.get('id'), html);
            }
          }
        }
      };
    },
    [],
  );

  return (
    <ReferenceCitationActions deleteReference={deleteReference}>
      <div className={styles.ReferenceCitationEditor}>
        <Toolbar
          clearFormatting={editorHook.clearFormatting}
          fontStyles={editorHook.fontStyles}
          toggleFontStyle={editorHook.toggleFontStyle}
          toggleScriptStyle={editorHook.toggleScriptStyle}
          disableLink={editorHook.disableLink}
          link={editorHook.link}
          toggleLink={editorHook.toggleLink}
          layoutId={layoutId}
          returnFocusToEditor={editorHook.focusEditor}
        />
        <div className={classNames({
          [styles.editorModeLexicalWrapper]: isLexicalMode,
        })}>
          {isDraftjsMode && (
            <ReferencesEditor
              ref={draftjsHook.editor}
              editorState={draftjsHook.editorState}
              onEditorChange={draftjsHook.onEditorStateChange}
            />
          )}
          {isLexicalMode && (
            <div className={styles.lexicalEditor}>
              <LexicalReferencesEditor
                ref={lexicalHook.ref}
                editMode={true}
                onChange={lexicalHook.onChange}
              />
            </div>
          )}
        </div>
      </div>
    </ReferenceCitationActions>
  );
};

export default ReferenceCitationEditor;
