import { useCallback, useEffect, useRef } from 'react';
import * as Constants from 'const';
import * as Models from 'models';
import * as brandStylesUtils from 'utils/brandStyles';
import { TextEditorHook } from './TextEditorHook';
import { BrandProps } from './useBrandProps';
import useStyles from './useStyles';

type HookProps = {
  activeLayer: Constants.Layer;
  relation: Models.LayeredRegularRelationMap<Models.TextRelationStyles>;
  isAutoFitContent: boolean;
  toggleAutoFitContent: () => void;
};

type Hook = {
  setBrandStyleAndAutofit: (style: Models.BrandStyleMap, needToToggleAutoHeight: boolean) => void;
  setBrandStyle: (style: Models.BrandStyleMap) => void;
};

export default function useBrandStyle(
  props: HookProps,
  brandProps: BrandProps,
  editorHook: TextEditorHook,
  stylesHook: ReturnType<typeof useStyles>,
): Hook {

  const brandPropsRef = useRef(brandProps);
  brandPropsRef.current = brandProps;
  const prevBrandPropsRef = useRef(brandProps);

  const { brandStyles } = brandProps;

  const { applyBrandStyleValues: editorApplyBrandStyleValues } = editorHook;

  const { isAutoFitContent, toggleAutoFitContent } = props;
  const { applyBrandStyle: stylesApplyBrandStyle } = stylesHook;

  const setBrandStyleAndAutofit = useCallback((
    brandStyle: Models.BrandStyleMap,
    needToToggleAutoHeight: boolean,
  ): void => {
    const { colors, fonts } = brandPropsRef.current;
    const textStyles = brandStylesUtils.getTextStylesFromBrandStyle(brandStyle, colors, fonts);

    editorApplyBrandStyleValues(textStyles);

    let brandStyleChanged = false;
    if (needToToggleAutoHeight) {
      if (!isAutoFitContent) {
        toggleAutoFitContent();
      }
    } else if (!isAutoFitContent) {
      brandStyleChanged = true;
    }

    stylesApplyBrandStyle(brandStyle, textStyles, brandStyleChanged);
  }, [
    editorApplyBrandStyleValues,
    isAutoFitContent,
    toggleAutoFitContent,
    stylesApplyBrandStyle,
  ]);

  const setBrandStyle = useCallback((brandStyle: Models.BrandStyleMap): void => {
    setBrandStyleAndAutofit(brandStyle, true);
  }, [setBrandStyleAndAutofit]);

  useEffect(() => {
    const { relation, activeLayer } = props;
    const { brandStyles: prevBrandStyles } = prevBrandPropsRef.current;
    // skip run on mount
    if (prevBrandStyles === brandStyles) {
      return;
    }

    // we intentionally use relation.styles instead of stylesHook.styles
    const styles = relation.getIn(['styles', activeLayer]);
    const brandStyleId = styles?.get('brandStyleId');
    const brandStyleChanged = !isAutoFitContent || styles?.get('brandStyleChanged');

    if (!brandStyles
      || !brandStyleId
      || brandStyleChanged
      // @ts-expect-error: immutable types confusion
      || brandStyles.equals(prevBrandStyles)
    ) {
      return;
    }

    // ??? not trivial approach
    setBrandStyleAndAutofit(brandStyles.get(brandStyleId), true);
    stylesHook.ensureStylesToBeKept();
  }, [brandStyles]);

  useEffect(() => {
    prevBrandPropsRef.current = brandProps;
  });

  return { setBrandStyleAndAutofit, setBrandStyle };
}
