import React from 'react';
import ReactDOM from 'react-dom';
import { HeaderIds } from 'const/Header';
import UndoRedoControl from '../UndoRedoControl';
import css from './styles.module.scss';

type Props = {
  undo: () => void;
  redo: () => void;
  isRedoDisabled: boolean;
  isUndoDisabled: boolean;
};

const UndoPortal: React.FunctionComponent<Props> = (props) => {
  const { undo, redo, isRedoDisabled, isUndoDisabled } = props;

  const htmlElement = document.getElementById(HeaderIds.UNDO_REDO);

  if (!htmlElement) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={css.undoRedoControl}>
      <UndoRedoControl
        undo={undo}
        redo={redo}
        isRedoDisabled={isRedoDisabled}
        isUndoDisabled={isUndoDisabled}
      />
    </div>,
    htmlElement,
  );
};

export default UndoPortal;
