import { useMemo } from 'react';
import * as Models from 'models';
import { CTAStylesSetters } from '../styles';
import { SizeResetHook } from './useSizeReset';
import { StylesHook } from './useStyles';


export const useModifiedStylesSetters = (
  stylesHook: StylesHook,
  sizeResetHook: SizeResetHook,
  getMaxHeight: () => number,
): CTAStylesSetters => {

  const { styles, setters: stylesSetters, getMaxWidth } = stylesHook;
  const { resetHeight, resetWidth } = sizeResetHook;

  return useMemo((): CTAStylesSetters => ({
    ...stylesSetters,
    border: (value: Models.BorderMap): void => {
      resetHeight(styles.height, styles.padding, value);
      resetWidth(styles.padding, value);
      stylesSetters.border(value);
    },
    padding: (value: Models.PaddingMap): void => {
      resetHeight(styles.height, value, styles.border);
      resetWidth(value, styles.border);
      stylesSetters.padding(value);
    },
    fitToCell: (value): void => {
      if (value) {
        return stylesSetters.fitToCell(true);
      }
      if (styles.fitToCell) {
        stylesSetters.height(getMaxHeight());
        stylesSetters.width(getMaxWidth());
      }
      stylesSetters.fitToCell(false);
    },
    height: (value): void => {
      resetHeight(value, styles.padding, styles.border);
      stylesSetters.height(value);
      if (styles.fitToCell) {
        stylesSetters.width(getMaxWidth());
        stylesSetters.fitToCell(false);
      }
    },
    width: (value): void => {
      stylesSetters.width(value);
      if (styles.fitToCell) {
        stylesSetters.height(getMaxHeight());
        stylesSetters.fitToCell(false);
      }
    },
  }), [styles, stylesSetters, resetHeight, resetWidth, getMaxHeight, getMaxWidth]);
};
