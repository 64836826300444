import classNames from 'classnames';
import { stateToHTML } from 'draft-js-export-html';
import { ReferencesEditor } from 'modules/draftjs';
import { useDraftjsReferencesEditor } from 'modules/draftjs/hooks/useDraftjsReferencesEditor';
import { LexicalReferencesEditor } from 'modules/Lexical/components/LexicalReferencesEditor';
import { generateHtml } from 'modules/Lexical/editorUtils';
import { useLexicalReferencesEditor } from 'modules/Lexical/hooks/useLexicalReferencesEditor';
import { ReferencesEditorHook } from 'modules/References/models/ReferencesEditorHook';
import * as React from 'react';
import { Button } from 'react-bootstrap';
import { useEditorToggle } from 'components/ArtboardAssets/useEditorToggle';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import Toolbar from 'components/Toolbar/ReferenceCitation';
import { Priority } from 'hooks/useClickOutside/constants';
import { cleanUpHtml } from 'utils/cleanUpHtml';
import { getReferenceCitationStateToHTMLOptions, hasText } from 'utils/editor';
import { intlGet } from 'utils/intlGet';
import { AddReferenceCitationProps } from './models';
import styles from './styles.module.scss';

const AddReferenceCitation: React.FunctionComponent<AddReferenceCitationProps> = (props) => {
  const { addCitation, hideModal, layoutId } = props;

  const { isLexicalMode, isDraftjsMode } = useEditorToggle();

  const draftjsHook = useDraftjsReferencesEditor();
  const lexicalHook = useLexicalReferencesEditor();

  const editorHook: ReferencesEditorHook = isLexicalMode ? lexicalHook : draftjsHook;

  const onSaveClick = (): void => {
    if (isDraftjsMode) {
      const text = stateToHTML(draftjsHook.editorState.getCurrentContent(), getReferenceCitationStateToHTMLOptions());
      addCitation(cleanUpHtml(text));
    }

    if (isLexicalMode) {
      const text = generateHtml(lexicalHook.ref.current);
      if (text) {
        addCitation(cleanUpHtml(text));
      }
    }
  };

  const isSaveButtonDisabled = isLexicalMode ?
    !lexicalHook.hasTextContent :
    !hasText(draftjsHook.editorState);

  return (
    <div className={styles.AddReferenceCitation}>
      <header className={styles.header}>
        <div>{intlGet('AddCitationModal', 'Title')}</div>
        <Icon className={styles.closeIcon} onClick={hideModal} type={IconType.CLOSE} size="sm-md" color="primary" />
      </header>
      <main className={styles.main}>
        <div className={styles.tip}>{intlGet('AddCitationModal', 'Tip')}</div>
        <div className={styles.content}>
          <Icon type={IconType.REFERENCE_CITATION} size="sm-md" color="primary" />
          <div className={styles.editor}>
            <Toolbar
              className={styles.toolbar}
              showTitle={false}
              clearFormatting={editorHook.clearFormatting}
              fontStyles={editorHook.fontStyles}
              toggleFontStyle={editorHook.toggleFontStyle}
              toggleScriptStyle={editorHook.toggleScriptStyle}
              disableLink={editorHook.disableLink}
              link={editorHook.link}
              toggleLink={editorHook.toggleLink}
              layoutId={layoutId}
              priority={Priority.MODAL_WINDOW_CONTROL}
              renderViaPortal={false}
              returnFocusToEditor={editorHook.focusEditor}
            />
            <div className={classNames({
              [styles.editorModeLexicalWrapper]: isLexicalMode,
            })}>
              {isDraftjsMode && (
                <ReferencesEditor
                  ref={draftjsHook.editor}
                  placeholder={intlGet('AddCitationModal', 'Placeholder')}
                  editorState={draftjsHook.editorState}
                  onEditorChange={draftjsHook.onEditorStateChange}
                />
              )}
              {isLexicalMode && (
                <div className={styles.lexicalEditor}>
                  <LexicalReferencesEditor
                    ref={lexicalHook.ref}
                    editMode={true}
                    onChange={lexicalHook.onChange}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
      <footer className={styles.footer}>
        <Button variant='secondary' onClick={hideModal}>{intlGet('AddCitationModal', 'Cancel')}</Button>
        <Button variant='primary' onClick={onSaveClick} disabled={isSaveButtonDisabled}>{intlGet('AddCitationModal', 'Save')}</Button>
      </footer>
    </div>
  );
};

export default AddReferenceCitation;
