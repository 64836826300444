import { OrderedSet } from 'immutable';
import { LexicalEditor } from 'lexical';
import { LexicalAbbreviationValues } from 'modules/Abbreviations/Lexical/AbbreviationPlugin';
import { ABBREVIATION_CHANGE } from 'modules/Abbreviations/Lexical/AbbreviationPlugin/commands';
import { $selectAbbreviation } from 'modules/Abbreviations/Lexical/AbbreviationPlugin/utils';
import { BASE_PROPS_COMMAND, BaseProps } from 'modules/Lexical/Plugins/BasePropsPlugin';
import { FONT_COMMAND, FontProps } from 'modules/Lexical/Plugins/FontPlugin';
import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EditorHook, EditorProps, EditorSetters } from 'components/ArtboardAssets/CallToAction/editor';
import { SizeResetHook } from 'components/ArtboardAssets/CallToAction/hooks/useSizeReset';
import * as Constants from 'const';
import * as Models from 'models';

export type LexicalOnChangeCTA = {
  abbreviations: (values: LexicalAbbreviationValues) => void;
  baseProps: (values: BaseProps) => void;
  font: (values: FontProps) => void;
};

type LexicalTextEditorHook = EditorHook & {
  editorRef: RefObject<LexicalEditor>;
  onChange: LexicalOnChangeCTA;
};

export const useEditorLexical = (
  sizeResetHook: SizeResetHook,
  editorWrap: React.RefObject<HTMLDivElement>): LexicalTextEditorHook => {

  const { resetHeightInSetters, resetHeightOnEffect } = sizeResetHook;

  const ref = useRef<LexicalEditor>(null);
  const editor = ref.current;

  const returnFocusToEditor = useCallback(() => {
    ref.current?._rootElement?.focus();
  }, []);

  const [props, setProps] = useState<EditorProps>({
    abbreviationId: undefined,
    abbreviationTerm: undefined,
    fontColor: undefined,
    fontFamily: undefined,
    fontSize: undefined,
    inlineStyle: OrderedSet<Constants.InlineStyle>([]),
    scriptStyle: undefined,
  });

  const getData = useCallback(() => {
    return { text: '', rawContent: '', title: '' }; // IN-PROGRESS
  }, []);

  const setters = useMemo((): EditorSetters => ({
    abbreviationId: (id: string | undefined) => editor?.dispatchCommand(ABBREVIATION_CHANGE, id),
    fontColor: brandColor => editor?.dispatchCommand(FONT_COMMAND.BRAND_COLOR, brandColor),
    fontFamily: (
      brandFont: Models.BrandFontMap,
      characterStyle: Models.CharacterStyleMap,
    ) => editor?.dispatchCommand(FONT_COMMAND.BRAND_FONT, { brandFont, characterStyle }),
    fontSize: (value: number): void => {
      const minHeight = Constants.CallToActionLineHeightModifier * value;
      resetHeightInSetters(minHeight);
      editor?.dispatchCommand(FONT_COMMAND.SIZE, value);
    },
    inlineStyle: (style: Constants.InlineStyle) => editor?.dispatchCommand(
      FONT_COMMAND.INLINE_STYLE,
      style,
    ),
    scriptStyle: (value: Constants.ScriptType) => editor?.dispatchCommand(BASE_PROPS_COMMAND.SCRIPT_STYLE, value),
  }), [editor, resetHeightInSetters]);

  const onChange: LexicalOnChangeCTA = useMemo(() => ({
    abbreviations: values => setProps(prev => ({
      ...prev,
      abbreviationId: values.abbreviationId,
      abbreviationTerm: values.abbreviationTerm,
    })),
    baseProps: (values: BaseProps) => setProps(prev => ({
      ...prev,
      scriptStyle: values.scriptStyle,
    })),
    font: values => setProps(prev => ({
      ...prev,
      fontColor: values.brandColor,
      fontFamily: values.brandFont,
      fontSize: values.size,
      inlineStyle: values.inlineStyle,
    })),
  }), [setProps]);

  useEffect(() => {
    const { current } = editorWrap;
    if (current) {
      current.style.height = 'auto';
    }
    resetHeightOnEffect();
  }, [editor?.getEditorState()]);

  const setCursorOnAbbreviation = (abbreviationId: string, abbreviationNumber: number | undefined): boolean => {
    let results = false;
    ref.current?.update(() => {
      results = $selectAbbreviation(abbreviationId, abbreviationNumber);
    }, { discrete: true });

    return results;
  };

  const applySelection = (): void => { };

  return {
    editorRef: ref,
    onChange,
    returnFocusToEditor,
    getData,
    setCursorOnAbbreviation,
    applySelection,
    setters,
    props,
  };
};
