import Immutable from 'immutable';
import _ from 'lodash';
import { useCallback, useRef } from 'react';
import * as Constants from 'const';
import * as relationsActions from 'containers/Relations/actions';
import { textComponentFactory } from 'factories/document/textComponentFactory';
import * as Models from 'models';
import { prioritizeLayeredRelations } from 'utils/prioritizeLayeredRelations';
import { removeTags } from 'utils/removeTags';
import { stylesComparator } from 'utils/styles/stylesComparator';
import useStyles from '../hooks/useStyles';
import { TextProps } from '../models';
import { stylesToSource } from '../utils/styles';
import { TextEditorHook } from './TextEditorHook';
import useCell from './useCell';

type SaveHandler = () => void;

export default function useSave(
  props: TextProps,
  editorHook: TextEditorHook,
  stylesHook: ReturnType<typeof useStyles>,
  cellHook: ReturnType<typeof useCell>,
): SaveHandler {

  const propsRef = useRef(props);
  propsRef.current = props;

  const editorHookRef = useRef(editorHook);
  editorHookRef.current = editorHook;

  const stylesHookRef = useRef(stylesHook);
  stylesHookRef.current = stylesHook;

  const cellHookRef = useRef(cellHook);
  cellHookRef.current = cellHook;

  return useCallback((): void => {
    const {
      activeLayer,
      addTextToCell,
      batchActions,
      country,
      document,
      language,
      layoutId,
      layoutRelations,
      relation,
      sectionStyles,
      isAutoFitContent,
    } = propsRef.current;
    const {
      resetCellWidhtAndHeightChange,
      props: { cellWidthChanged, cellHeightChanged },
    } = cellHookRef.current;

    const updatedRelation = relation.updateIn(['styles', activeLayer], values => values.withMutations(
      value => stylesToSource(stylesHookRef.current.styles, value)
        .set('isAutoFitContent', isAutoFitContent),
    ));
    const updatedRelations = layoutRelations.set(updatedRelation.get('id'), updatedRelation);
    const areStylesEqual = _.isEqualWith(
      updatedRelation.getIn(['styles', activeLayer]).toJS(),
      relation.getIn(['styles', activeLayer]).toJS(),
      stylesComparator,
    );

    if (editorHookRef.current.hasTextContent) {
      // no need to save text if there are no changes
      if (!editorHookRef.current.hasChanges && areStylesEqual) {
        // need to set relations because cell height/width may have been modified while edit mode was active
        if (cellWidthChanged || cellHeightChanged) {
          relationsActions.updateLayeredRelations(updatedRelations);
        }

        return;
      }

      const { text, rawContent, operations } = editorHookRef.current.flushDataToSave();
      let updatedTextComponent: Models.TextComponentMap;

      if (!document) {
        updatedTextComponent = Immutable.fromJS(textComponentFactory({
          id: relation.getIn(['documentId', activeLayer], undefined),
          rawContent,
          status: Constants.TextStatusType.EDIT,
          text,
          language: language.toJS(),
          country: country.toJS(),
        }));
      } else {
        updatedTextComponent = document.withMutations(doc => doc
          .set('rawContent', rawContent)
          .set('status', Constants.TextStatusType.EDIT)
          .set('text', text)
          .set('name', removeTags(text))
          // https://issues.merck.com/browse/DCC-4748
          .set('language', language)
          .set('country', country),
        );
      }
      const prioritizedUpdatedRelations = prioritizeLayeredRelations(updatedRelations, activeLayer);
      addTextToCell(
        updatedRelation.get('id'),
        prioritizedUpdatedRelations,
        updatedTextComponent,
        layoutId,
        operations,
        sectionStyles,
      );

      cellHookRef.current.resetCellWidhtAndHeightChange();

      return;
    }

    const actions: Models.IAction[] = [];

    if (cellWidthChanged || cellHeightChanged || !areStylesEqual) {
      actions.push(relationsActions.updateLayeredRelations(updatedRelations));
    }

    if (document) {
      actions.push(relationsActions.deleteComponents([document.get('id')]));
    }

    if (actions.length !== 0) {
      batchActions(actions);
    }

    editorHookRef.current.clearOperations?.();
    resetCellWidhtAndHeightChange();
  }, []);
}
