import { TextEditorSetters } from '../hooks/TextEditorHook';
import { UndoMiddleware } from '../hooks/useDraftjsTextUndo';
import { StylesSetters } from './styles';

export function wrapStylesSettersWithUndoMiddleware(
  setters: StylesSetters,
  middleware: UndoMiddleware,
): StylesSetters {
  return {
    ...setters,
    backgroundColor: middleware(setters.backgroundColor, 'backgroundColor'),
    backgroundColorOpacity: setters.backgroundColorOpacity,
    backgroundGradient: middleware(setters.backgroundGradient),
    backgroundImage: middleware(setters.backgroundImage),
    border: middleware(setters.border, 'border'),
    borderRadius: middleware(setters.borderRadius, 'borderRadius'),
    padding: middleware(setters.padding, 'padding'),
    verticalAlignment: middleware(setters.verticalAlignment, 'verticalAlignment'),
  };
}

export function wrapEditorSettersWithUndoMiddleware(
  setters: TextEditorSetters,
  middleware: UndoMiddleware,
): TextEditorSetters {
  return {
    abbreviationId: middleware(setters.abbreviationId),
    blockLineHeight: middleware(setters.blockLineHeight),
    blockType: middleware(setters.blockType),
    bulletColor: middleware(setters.bulletColor),
    fontColor: middleware(setters.fontColor),
    fontFamily: middleware(setters.fontFamily),
    fontSize: middleware(setters.fontSize),
    inlineStyle: middleware(setters.inlineStyle),
    link: setters.link,
    scriptStyle: middleware(setters.scriptStyle),
    textNoWrap: middleware(setters.textNoWrap),
  };
}
