import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';

type Props = {
  editMode: boolean;
};

export const EditModePlugin: React.FunctionComponent<Props> = (props) => {
  const { editMode } = props;
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (editMode !== editor.isEditable()) {
      editor.setEditable(editMode);
    }

    if (editMode) {
      editor.focus();
    }
  }, [editMode]);

  return null;
};
