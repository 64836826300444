import downloadFile from 'js-file-download';
import { call, select } from 'redux-saga/effects';

import { ISSUE_REPORT_NAME } from 'const';
import { lastError } from 'containers/App/selectors';
import { getArtboardsJson } from 'containers/Artboards/services/getArtboardsJson';
import { getProjectAssets } from 'containers/Common/services/getProjectAssets';
import * as Models from 'models';
import { ArtboardsJson } from 'services/ArtboardConverter/models';

export function* downloadIssueReport(): Generator {
  let artboardsJson: ArtboardsJson;
  let projectFile: Models.ProjectAssetsToUpload;
  let asmolError: Models.AssemblerError;

  try {
    artboardsJson = yield call(getArtboardsJson);
  } catch (error) {
    // eslint-disable-next-line no-console
    yield call([console, console.warn], 'Unable to generate artboards json');
  }

  try {
    projectFile = yield call(getProjectAssets);
  } catch (error) {
    // eslint-disable-next-line no-console
    yield call([console, console.warn], 'Unable to retrieve project file');
  }

  try {
    asmolError = yield select(lastError);
  } catch (error) {
    // eslint-disable-next-line no-console
    yield call([console, console.warn], 'Unable to retrieve last error');
  }

  try {
    const report = JSON.stringify({
      error: asmolError,
      projectFile,
      artboardsJson,
    });

    yield call(downloadFile, report, ISSUE_REPORT_NAME);
  } catch (error) {
    // eslint-disable-next-line no-console
    yield call([console, console.error], 'Error occurred during issue report generation', error);
  }
}
