/* eslint-disable no-use-before-define */
import { ListNode, ListType, SerializedListNode } from '@lexical/list';
import { EditorConfig, LexicalEditor, LexicalNode, NodeKey } from 'lexical';
import * as Models from 'models';

export type SerializedCustomListNode = SerializedListNode & {
  color: Models.BrandColor | undefined;
};

const CSS_PROPERTY_FOR_BULLET_COLOR = '--bullet-color';
export class CustomListNode extends ListNode {
  __color?: Models.BrandColor;

  static getType(): string {
    return 'custom-list';
  }

  constructor(listType: ListType, start: number, color?: Models.BrandColor, key?: NodeKey) {
    super(listType, start, key);
    this.__color = color;
  }

  static clone(node: CustomListNode): CustomListNode {
    return new CustomListNode(node.__listType, node.__start, node.__color, node.__key);
  }

  setColor(color: Models.BrandColor | undefined): void {
    this.getWritable().__color = color;
  }

  getColor(): Models.BrandColor | undefined {
    return this.getLatest().__color;
  }

  createDOM(config: EditorConfig, _editor?: LexicalEditor): HTMLElement {
    const element = super.createDOM(config, _editor);
    element.style.setProperty(CSS_PROPERTY_FOR_BULLET_COLOR, this.__color ? this.__color.HEX : null);

    return element;
  }

  updateDOM(prevNode: CustomListNode, dom: HTMLElement, config: EditorConfig): boolean {
    const isUpdated = super.updateDOM(prevNode, dom, config);
    dom.style.setProperty(CSS_PROPERTY_FOR_BULLET_COLOR, this.__color ? this.__color.HEX : null);

    return isUpdated;
  }

  exportJSON(): SerializedCustomListNode {
    const data = super.exportJSON() as unknown as SerializedCustomListNode;
    data.type = CustomListNode.getType();
    data.color = this.getColor();

    return data;
  }

  static importJSON(serializedNode: SerializedCustomListNode): CustomListNode {
    const node = super.importJSON(serializedNode) as CustomListNode;
    node.setColor(serializedNode.color);

    return node;
  }
}

export function $isCustomListNode(node: LexicalNode | null | undefined): node is CustomListNode {
  return node instanceof CustomListNode;
}

export function $createCustomListNode(listType: ListType, start: number, color?: Models.BrandColor): CustomListNode {
  return new CustomListNode(listType, start, color) ;
}
