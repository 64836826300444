import { DragSource, DragSourceCollector, DragSourceSpec } from 'react-dnd';

import ComponentItem from 'components/ComponentItem';
import { IComponentItemCollectedProps, IComponentItemOwnProps, IComponentItemProps } from 'components/ComponentItem/models';
import { DragSourceType, EntityType } from 'const';
import { callToActionMapFactory } from 'factories/document/callToActionFactory';
import { AssetDragObject } from 'models';

const ElementItemDragSource: DragSourceSpec<IComponentItemProps, AssetDragObject> = {
  beginDrag(props) {
    const { size, type, toggleDragState } = props;
    const component = callToActionMapFactory({ size });

    if (toggleDragState) {
      toggleDragState(DragSourceType.ASSET, type);
    }

    return {
      component,
      type: type as EntityType,
    };
  },

  endDrag(props) {
    if (props.toggleDragState) {
      props.toggleDragState();
    }
  },

  canDrag() {
    return true;
  },
};

const collect: DragSourceCollector<IComponentItemCollectedProps, IComponentItemOwnProps> = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
};

export default DragSource<IComponentItemOwnProps, IComponentItemCollectedProps, AssetDragObject>(
  DragSourceType.ASSET,
  ElementItemDragSource,
  collect,
)(ComponentItem);
