import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import AnimateHeight from 'react-animate-height';
import { DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import AccessibleDiv from 'components/AccessibleDiv/AccessibleDiv';
import AssetActions from 'components/AssetActions';
import { useScrollIntoView } from 'components/AssetList/hooks';
import DocumentThumbnail from 'components/DocumentThumbnail';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import LoadingOverlay from 'components/LoadingOverlay';
import { DocumentSubtype, DragSourceType, ModalType, ProcessType } from 'const';
import LayoutListItemRendition from 'containers/LayoutListItemRendition';
import { Priority, useClickOutside } from 'hooks/useClickOutside';
import { LayoutDragObject, ReusableLayout } from 'models';
import { isGroupLayoutDocument } from 'utils/entityType';
import { GroupLayoutListItemDragSource, ReusableLayoutListItemDragSource, groupLayoutCollector, reusableLayoutCollector } from './dnd';
import * as Models from './models';
import styles from './styles.module.scss';

export const LayoutListItemContent: React.FunctionComponent<Models.LayoutListItemContentProps> = (props) => {
  const {
    children,
    container,
    document,
    isPreview,
    thumbnailUrl,
    layoutCount,
    isOutsideStoryCard,
  } = props;

  const [isExpanded, setIsExpanded] = React.useState(false);
  const handleToggleExpand = (): void => setIsExpanded(!isExpanded);

  const name = document.get('name');
  const number = document.get('number');
  const product = document.get('product');
  const productName = product?.first();

  const isGroupLayout = isGroupLayoutDocument(document);
  const allowExpandLayout = !isPreview && !isGroupLayout && document.get('subtype') === DocumentSubtype.CONTENT_UNIT;

  return (
    <div
      className={styles.content}
      ref={container}
    >
      <div className={styles.layoutWrapper}>
        <div className={styles.layout}>
          <div className={styles.iconWrapper}>
            <div className={styles.thumb}>
              {
                isGroupLayout &&
                <Icon className={styles.groupLayoutMarker} type={IconType.GROUP_LAYOUT} size="xs" color="primary" />
              }
              <DocumentThumbnail
                placeholder={<Icon type={IconType.IMAGE} size="sm" color="disabled" />}
                url={thumbnailUrl}
              />
            </div>
            {_.isFinite(layoutCount) && isOutsideStoryCard && <div className={styles.counter}>{layoutCount}</div>}
          </div>
          <div className={styles.info}>
            <div className={styles.title}>{name}</div>
            <div className={styles.name}>
              <span className={styles.name__primary}>{number}</span>
              {productName && <span className={styles.name__secondary}>{productName}</span>}
            </div>
          </div>
          {children}
        </div>
        {allowExpandLayout &&
          <AccessibleDiv
            className={styles.chevron}
            onClick={handleToggleExpand}
          >
            <Icon
              type={isExpanded ? IconType.CHEVRON_UP : IconType.CHEVRON_DOWN}
              size="badge"
              color="primary"
            />
          </AccessibleDiv>
        }
      </div>
      <AnimateHeight
        duration={300}
        easing="ease-out"
        height={isExpanded ? 'auto' : 0}
      >
        <LayoutListItemRendition document={document} />
      </AnimateHeight>
    </div>
  );
};

const LayoutListItem: React.FunctionComponent<Models.LayoutListItemProps> = (props) => {
  const {
    assetIdToScroll,
    connectGLDragPreview,
    connectGLDragSource,
    connectRLDragPreview,
    connectRLDragSource,
    deleteComponent,
    document,
    documentIdsAreBeingUploaded,
    isSelected,
    isGLDragging,
    isRLDragging,
    layoutCount,
    setAssetIdToScroll,
    addSelectedAssetPanelComponent,
    removeSelectedAssetPanelComponent,
    showModal,
    storyCardId,
  } = props;
  const layoutId = document.get('id');
  const thumbnailUrl = document.get('_thumbnailUrl');
  const isLayoutBeingUploaded = documentIdsAreBeingUploaded.has(layoutId);
  const { elementRef } = useScrollIntoView(layoutId, assetIdToScroll, setAssetIdToScroll);
  const { container } = useClickOutside<HTMLDivElement>(Priority.SELECTION, {
    active: isSelected,
    onClickOutside: () => removeSelectedAssetPanelComponent(layoutId),
  });

  const [connectDragPreview, connectDragSource, isDragging] = isGroupLayoutDocument(document)
    ? [connectGLDragPreview, connectGLDragSource, isGLDragging]
    : [connectRLDragPreview, connectRLDragSource, isRLDragging];

  React.useEffect(() => { connectDragPreview(getEmptyImage(), { captureDraggingState: true }); }, []);

  const openPreview = (): void => {
    showModal(ModalType.LAYOUT_DETAILS, { document: document.toJS() as ReusableLayout });
  };

  const deleteLayout = (): void => {
    const layoutIdToDelete = document.get('id');
    const entityType = document.get('entityType');
    deleteComponent(layoutIdToDelete, entityType);
  };

  const onSelectHandler = (): void => {
    addSelectedAssetPanelComponent(layoutId);
  };

  const isOutsideStoryCard = !storyCardId;

  return connectDragSource(
    (
      <div
        className={classNames(styles.LayoutListItem, {
          [styles.dragging]: isDragging,
          [styles.selected]: isSelected && isOutsideStoryCard,
        })}
        ref={elementRef}
        onMouseDown={onSelectHandler}
      >
        <LoadingOverlay isLocked={isLayoutBeingUploaded} activeProcessType={ProcessType.DOCUMENT_UPLOADING} spinnerSize="md" />
        <LayoutListItemContent
          container={container}
          document={document}
          thumbnailUrl={thumbnailUrl}
          layoutCount={layoutCount}
          isOutsideStoryCard={isOutsideStoryCard}
        >
          {
            !isLayoutBeingUploaded &&
            <AssetActions
              onDelete={isOutsideStoryCard ? deleteLayout : (): void => {}}
              onPreviewOpen={openPreview}
            />
          }
        </LayoutListItemContent>
      </div>
    ),
  );
};

export default _.flow(
  DragSource<Models.LayoutListItemOwnProps, Models.ReusableLayoutListItemCollectedProps, LayoutDragObject>(
    DragSourceType.REUSABLE_LAYOUT,
    ReusableLayoutListItemDragSource,
    reusableLayoutCollector,
  ),
  DragSource<Models.LayoutListItemOwnProps, Models.GroupLayoutListItemCollectedProps, LayoutDragObject>(
    DragSourceType.GROUP_LAYOUT,
    GroupLayoutListItemDragSource,
    groupLayoutCollector,
  ),
)(LayoutListItem);
