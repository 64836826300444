
import {
  COMMAND_PRIORITY_EDITOR,
  FORMAT_TEXT_COMMAND,
  LexicalEditor,
  createCommand,
} from 'lexical';
import * as Constants from 'const';

export const REFERENCES_FONT_COMMAND = {
  INLINE_STYLE: createCommand<Constants.ReferencesInlineStyle>(),
} as const;

export function registerCommands(
  editor: LexicalEditor,
): () => void {
  return editor.registerCommand(
    REFERENCES_FONT_COMMAND.INLINE_STYLE,
    (style) => {

      if (style === Constants.ReferencesInlineStyle.UNDERLINE) {
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');

        return true;
      }

      if (style === Constants.ReferencesInlineStyle.BOLD) {
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');

        return true;
      }

      if (style === Constants.ReferencesInlineStyle.ITALIC) {
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');

        return true;
      }

      return false;
    },
    COMMAND_PRIORITY_EDITOR,
  );
}
