import React from 'react';

import List from 'components/AssetList';
import Header from 'components/AssetList/Header';
import { useToggle } from 'components/AssetList/hooks';
import Button from 'components/Button';
import { IconType } from 'components/Icon/constants';
import { EntityType, ModalType } from 'const';
import CitationItem from 'containers/CitationItem';
import { ReferenceCitationMap } from 'models';
import { intlGet } from 'utils/intlGet';
import { ICitationListProps } from './models';
import styles from './styles.module.scss';

const CitationList: React.FunctionComponent<ICitationListProps> = (props) => {
  const {
    assetIdToScroll,
    citations,
    deleteComponent,
    documentIdsAreBeingUploaded,
    isExpand,
    setAssetIdToScroll,
    setSelectedCitation,
    addSelectedAssetPanelComponent,
    removeSelectedAssetPanelComponent,
    showModal,
    showUpdatedReferences,
    storyCardId,
    toggleDragState,
    toggleExpand,
    toggleUpdatedDocumentsDisplaying,
    updatedInPromoMats,
  } = props;

  const {
    expanded,
    toggle,
  } = useToggle({
    toggleUpdatedDocumentsDisplaying,
    showUpdatedDocuments: showUpdatedReferences,
    entityType: EntityType.REFERENCE_CITATION,
    toggle: toggleExpand,
    isExpand,
  });

  const renderSubheader = (): JSX.Element => (
    <div className={styles.subheader}>
      <Button
        onClick={showModal.bind(null, ModalType.ADD_REFERENCE_CITATION)}
        size="md"
        title={intlGet('CitationsList', 'AddNew')}
        icon={IconType.PLUS_FILLED}
      />
    </div>
  );

  return (
    <div className={styles.CitationListContainer} data-title="reference-citations">
      {
        !storyCardId &&
        <Header
          title={intlGet('Assets', 'ReferenceCitations', { count: citations ? citations.size : 0 })}
          showUpdatesHint={updatedInPromoMats && showUpdatedReferences}
          expanded={expanded}
          onClick={toggle}
        />
      }
      {
        (expanded || storyCardId) &&
        <List
          border={!storyCardId}
          elements={citations}
          subheader={!storyCardId && renderSubheader()}
          renderElement={(citation: ReferenceCitationMap): React.ReactNode => (
            <CitationItem
              allowDelete={!storyCardId}
              assetIdToScroll={assetIdToScroll}
              citation={citation}
              deleteComponent={deleteComponent}
              documentIdsAreBeingUploaded={documentIdsAreBeingUploaded}
              key={citation.get('id')}
              setAssetIdToScroll={setAssetIdToScroll}
              setSelectedCitation={setSelectedCitation}
              addSelectedAssetPanelComponent={addSelectedAssetPanelComponent}
              removeSelectedAssetPanelComponent={removeSelectedAssetPanelComponent}
              showModal={showModal}
              toggleDragState={toggleDragState}
            />
          )}
        />
      }
    </div>
  );
};

export default CitationList;
