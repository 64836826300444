import { DragSource, DragSourceCollector, DragSourceSpec } from 'react-dnd';

import ComponentItem from 'components/ComponentItem';
import { IComponentItemCollectedProps, IComponentItemOwnProps, IComponentItemProps } from 'components/ComponentItem/models';
import { DragSourceType, LayoutType } from 'const';
import { EmptyLayoutDragObject } from 'models';

const LayoutItemDragSource: DragSourceSpec<IComponentItemProps, EmptyLayoutDragObject> = {
  beginDrag(props) {
    const { type, toggleDragState, size } = props;
    if (toggleDragState) {
      toggleDragState(DragSourceType.LAYOUT, type);
    }

    return {
      type: type as LayoutType,
      size,
    };
  },

  endDrag(props) {
    if (props.toggleDragState) {
      props.toggleDragState();
    }
  },

  canDrag(props) {
    const { type, isReferenceCitationElementOnArtboard } = props;

    return !(type === LayoutType.REFERENCE_CITATION_ELEMENT && isReferenceCitationElementOnArtboard);
  },
};

const collect: DragSourceCollector<IComponentItemCollectedProps, IComponentItemOwnProps> = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
};

export default DragSource<IComponentItemOwnProps, IComponentItemCollectedProps, EmptyLayoutDragObject>(
  DragSourceType.LAYOUT,
  LayoutItemDragSource,
  collect,
)(ComponentItem);
