import { $getSelection, $isRangeSelection } from 'lexical';
import * as Constants from 'const';

export function $getInlineStyle(): Constants.ReferencesInlineStyle[] {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) {
    return [];
  }
  const result: Constants.ReferencesInlineStyle[] = [];

  if (selection.hasFormat('bold')) {
    result.push(Constants.ReferencesInlineStyle.BOLD);
  }

  if (selection.hasFormat('italic')) {
    result.push(Constants.ReferencesInlineStyle.ITALIC);
  }

  if (selection.hasFormat('underline')) {
    result.push(Constants.ReferencesInlineStyle.UNDERLINE);
  }

  return result;
}
