import PluginEditor from '@draft-js-plugins/editor';
import Draft from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { selectAbbreviationsData } from 'modules/Abbreviations/store/selectors';
import { useDraftjsEditor, useDraftjsEditorState, Features, DraftjsEditorStateSetter } from 'modules/draftjs';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IMap } from 'typings/DeepIMap';
import * as Constants from 'const';
import { getActiveLayer } from 'hooks/useActiveLayer';
import { useProjectType } from 'hooks/useProjectType';
import * as Models from 'models';
import * as editorUtils from 'utils/editor';
import { prioritizeLayeredRelation } from 'utils/prioritizeLayeredRelations';
import { EditorHook } from '../editor';
import { SizeResetHook } from './useSizeReset';

type DraftjsType = {
  editorRef: React.RefObject<PluginEditor>;
  editorState: Draft.EditorState;
  setEditorState: DraftjsEditorStateSetter;
  onEditorChange: (editorState: Draft.EditorState) => void;
};

type CallToActionEditorHookProps = {
  document: Models.CallToActionMap;
  relation: Models.LayeredRegularRelationMap<Models.CallToActionStyles>;
  flatColorsByRelationId: IMap<Models.BrandColorsRecord>;
  flatFontsByRelationId: IMap<Models.BrandFontsRecord>;
};

export const useEditorDraftjs = (
  props: CallToActionEditorHookProps,
  sizeResetHook: SizeResetHook,
  editorWrap: React.RefObject<HTMLDivElement>): EditorHook & DraftjsType => {
  const {
    document,
    relation,
    flatColorsByRelationId,
    flatFontsByRelationId,
  } = props;

  const projectType = useProjectType();
  const activeLayer = getActiveLayer();
  const relationId = relation.get('id');
  const colors = flatColorsByRelationId.get(relationId);
  const fonts = flatFontsByRelationId.get(relationId);

  const { resetHeightInSetters, resetHeightOnEffect } = sizeResetHook;

  const abbreviationsData = useSelector(selectAbbreviationsData);

  const getInitialEditorState = (): Draft.EditorState => editorUtils.getEditorStateFromCallToAction(
    document, prioritizeLayeredRelation(relation, activeLayer), colors, fonts, projectType,
  );

  const draftjsState = useDraftjsEditorState(getInitialEditorState, true);
  const { editorState, setEditorState } = draftjsState;

  const draftjs = useDraftjsEditor(
    draftjsState,
    {
      projectType,
      colors,
      fonts,
      abbreviationsData,
    },
    [Features.ABBREVIATIONS, Features.DECORATORS, Features.FONT_COLOR, Features.INLINE_STYLE_EXTENDED],
  );
  const { resetEditorState, returnFocusToEditor } = draftjs;

  useEffect(() => {
    resetEditorState(getInitialEditorState());
  }, [document, activeLayer]);

  const getData = useCallback(() => {
    const state = editorState.getCurrentContent();
    const rawContent = JSON.stringify(Draft.convertToRaw(state));
    const title = state.getPlainText();
    const text = stateToHTML(state, editorUtils.getTextStateToHTMLOptions(colors, fonts));

    return { text, rawContent, title };
  }, [editorState]);

  const draftjsSetters = draftjs.setters;

  const setters = useMemo((): typeof draftjsSetters => ({
    ...draftjsSetters,
    fontSize: (value: number): void => {
      const minHeight = Constants.CallToActionLineHeightModifier * value;
      resetHeightInSetters(minHeight);
      draftjsSetters.fontSize(value);
      returnFocusToEditor();
    },
    fontFamily: (font: Models.BrandFontMap, characterStyle: Models.CharacterStyleMap): void => {
      draftjsSetters.fontFamily(font, characterStyle);
      returnFocusToEditor();
    },
  }), [resetHeightInSetters, returnFocusToEditor, draftjsSetters]);

  useEffect(() => {
    const { current } = editorWrap;
    if (current) {
      current.style.height = 'auto';
    }
    resetHeightOnEffect();
  }, [editorState]);

  const setCursorOnAbbreviation = (abbreviationId: string, abbreviationNumber: number | undefined): boolean => {
    const newState = editorUtils.selectAbbreviation(
      editorState,
      abbreviationId,
      abbreviationNumber,
    );
    if (!newState) {
      return false;
    }
    setEditorState(newState);

    return true;
  };

  const applySelection = (blockKey: string, start: number, end: number): void => {
    setEditorState(editorUtils.applySelection(editorState, blockKey, start, end));
  };

  return {
    editorRef: draftjs.editorRef,
    editorState,
    setEditorState: draftjsState.setEditorState,
    onEditorChange: draftjs.onEditorChange,
    props: draftjs.props,
    setters,
    getData,
    returnFocusToEditor,
    setCursorOnAbbreviation,
    applySelection,
  };
};
