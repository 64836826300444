import {
  ParagraphNode,
  SerializedParagraphNode,
  LexicalNode,
  EditorConfig,
  NodeKey,
} from 'lexical';

/**
* Custom Paragraph Node with dynamic line-height functionality
*/
export class CustomParagraphNode extends ParagraphNode {
  __lineHeight: number | undefined;

  constructor(lineHeight?: number, key?: NodeKey) {
    super(key);
    this.__lineHeight = lineHeight;
  }

  static getType(): string {
    return 'custom-paragraph';
  }

  static clone(node: CustomParagraphNode): CustomParagraphNode {
    return new CustomParagraphNode(node.__lineHeight, node.__key);
  }

  createDOM(config: EditorConfig): HTMLElement {
    const element = super.createDOM(config);
    if (this.__lineHeight) {
      element.style.lineHeight = this.__lineHeight.toString();
    }

    return element;
  }

  updateDOM(prevNode: CustomParagraphNode, dom: HTMLElement, config: EditorConfig): boolean {
    const isUpdated = super.updateDOM(prevNode, dom, config);

    const prevLineHeight = prevNode.__lineHeight;
    const nextLineHeight = this.__lineHeight;

    if (prevLineHeight !== nextLineHeight) {
      dom.style.lineHeight = nextLineHeight ? nextLineHeight.toString() : '';
    }

    return isUpdated;
  }

  static importJSON(
    serializedNode: SerializedParagraphNode & { lineHeight?: number },
  ): CustomParagraphNode {
    const node = super.importJSON(serializedNode) as CustomParagraphNode;
    if (serializedNode.lineHeight) {
      node.setLineHeight(serializedNode.lineHeight);
    }

    return node;
  }

  exportJSON(): SerializedParagraphNode & { lineHeight?: number } {
    const json = super.exportJSON();

    return {
      ...json,
      type: this.getType(),
      lineHeight: this.__lineHeight ?? undefined,
    };
  }

  setLineHeight(lineHeight: number): void {
    this.getWritable().__lineHeight = lineHeight;
  }

  getLineHeight(): number | undefined {
    return this.getLatest().__lineHeight;
  }
}

export function $createCustomParagraphNode(lineHeight?: number): CustomParagraphNode {
  return new CustomParagraphNode(lineHeight);
}

export function $isCustomParagraphNode(
  node: LexicalNode | null | undefined,
): node is CustomParagraphNode {
  return node instanceof CustomParagraphNode;
}
