import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot } from 'lexical';
import { useEffect } from 'react';

type Props = {
  onChange: (text: string) => void;
};

export function TextContentPlugin(props: Props): null {
  const { onChange } = props;
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const readEditorStateAndNotifyChange = (): void => {
      const text = $getRoot().getTextContent();
      onChange(text);
    };
    editor.getEditorState().read(readEditorStateAndNotifyChange);

    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(readEditorStateAndNotifyChange);
    });
  }, [editor, onChange]);

  return null;
}
