import React, { useCallback } from 'react';
import AccessibleDiv from 'components/AccessibleDiv/AccessibleDiv';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { intlGet } from 'utils/intlGet';
import styles from './styles.module.scss';

type Props<T> = {
  active: boolean;
  label: IconType;
  onToggle: (value: T) => void;
  value: T;
  title: string;
};

export default function EditorToolbarButton<T=string>(props: Readonly<Props<T>>): JSX.Element {
  const { active, title, label, value, onToggle } = props;

  const toggleHandler = useCallback((event: React.SyntheticEvent<EventTarget>): void => {
    event.preventDefault();
    onToggle(value);
  }, [onToggle, value]);

  return (
    <AccessibleDiv
      className={styles.editorToolbarButton}
      data-title={title}
      onMouseDown={toggleHandler}
    >
      <Icon
        type={label}
        size="badge"
        color={active ? 'primary' : 'secondary'}
        hoverColor="primary-hover"
        hoverTooltipText={intlGet('EditorToolbar.Tooltip', label)}
      />
    </AccessibleDiv>
  );
}
