import { SerializedLinkNode } from '@lexical/link';
import { SerializedMarkNode } from '@lexical/mark/MarkNode';
import Draft from 'draft-js';
import { SerializedRootNode } from 'lexical';
import * as BrandDefinition from 'modules/BrandDefinition';
import * as Constants from 'const';
import * as Models from 'models';
import { getBrandColor } from 'utils/editor/style';
import * as inlineStylesUtils from 'utils/inlineStyles';
import { toPx } from 'utils/toPx';
import { SerializedCustomListNode } from '../nodes/CustomListNode';
import { NO_WRAP_STYLES_OBJECT } from '../Plugins/BasePropsPlugin/style';
import { FontPluginStyle } from '../Plugins/FontPlugin/style';
import { lexicalListItemNodeBase, lexicalListNodeBase } from './constants';
import { BulletListType, ConvertStylesEnum, LexicalFieldDescribes, LexicalFormatCodeEnum, RawContentLine, TSerializedLexicalNode } from './types';

export function getFormateCode(styles: string[]): number {
  return styles.reduce((acc, curr) => {
    const isUnderline = curr.includes(ConvertStylesEnum.UNDERLINE) ? LexicalFormatCodeEnum.UNDERLINE : LexicalFormatCodeEnum.DEFAULT;
    const isSub = curr.includes(ConvertStylesEnum.SUB) ? LexicalFormatCodeEnum.SUB : LexicalFormatCodeEnum.DEFAULT;
    const isSup = curr.includes(ConvertStylesEnum.SUP) ? LexicalFormatCodeEnum.SUP : LexicalFormatCodeEnum.DEFAULT;

    return acc + isUnderline + isSup + isSub;
  }, 0);
}

export function getStyleArray(
  styles: string[],
  colors: Models.BrandColorsList,
  fonts: Models.BrandFontsList,
): string[] {
  const result: string[] = [];

  const brandColorHEX = inlineStylesUtils.getInlineStylesValue(styles, Constants.StylePrefix.FONT_COLOR);
  if (brandColorHEX) {
    result.push(`${FontPluginStyle.BRAND_COLOR_HEX}: ${brandColorHEX}`);
    const brandColorName = inlineStylesUtils.getInlineStylesValue(styles, Constants.StylePrefix.FONT_COLOR_NAME);
    if (brandColorName) {
      result.push(`${FontPluginStyle.BRAND_COLOR_NAME}: ${brandColorName}`);
    }
    const barndColorTint = inlineStylesUtils.getInlineStylesValue(styles, Constants.StylePrefix.FONT_COLOR_TINT, 'number');
    if (barndColorTint) {
      result.push(`${FontPluginStyle.BRAND_COLOR_TINT}: ${barndColorTint}`);
    }
    const color = BrandDefinition.getCSSColorFromBrandColor(
      { name: brandColorName, HEX: brandColorHEX, tint: barndColorTint },
      colors,
    );
    result.push(`${FontPluginStyle.COLOR}: ${color}`);
  }

  const brandFontName = inlineStylesUtils.getInlineStylesValue(styles, Constants.StylePrefix.FONT_FAMILY);
  if (brandFontName) {
    result.push(`${FontPluginStyle.BRAND_FONT_NAME}: ${brandFontName}`);
    const characterStyleName = inlineStylesUtils.getInlineStylesValue(styles, Constants.StylePrefix.CHARACTER_STYLE_NAME);
    if (characterStyleName) {
      result.push(`${FontPluginStyle.CHARACTER_STYLE_NAME}: ${characterStyleName}`);
    }
    const fontFamily = BrandDefinition.getCSSFontFamilyFromBrandFont(
      brandFontName,
      characterStyleName,
      fonts,
    );
    result.push(`${FontPluginStyle.FONT_FAMILY}: ${fontFamily}`);
  }

  const fontSize = inlineStylesUtils.getInlineStylesValue(styles, Constants.StylePrefix.FONT_SIZE);
  const fontSizePx = toPx(fontSize);
  if (fontSizePx) {
    result.push(`${FontPluginStyle.FONT_SIZE}: ${fontSizePx}`);
  }

  const fontStyle = inlineStylesUtils.getInlineStylesValue(styles, Constants.StylePrefix.FONT_STYLE);
  if (fontStyle) {
    result.push(`${FontPluginStyle.FONT_STYLE}: ${fontStyle}`);
  }

  const fontWeight = inlineStylesUtils.getInlineStylesValue(styles, Constants.StylePrefix.FONT_WEIGHT);
  if (fontWeight) {
    result.push(`${FontPluginStyle.FONT_WEIGHT}: ${fontWeight}`);
  }

  const bulletColor = inlineStylesUtils.getInlineStylesValue(styles, Constants.StylePrefix.BULLET_COLOR);
  const bulletColorName = inlineStylesUtils.getInlineStylesValue(styles, Constants.StylePrefix.BULLET_COLOR_NAME);
  if (bulletColor) {
    result.push(`bullet-color: ${bulletColor}`);
  }

  if (bulletColorName) {
    result.push(`bullet-color-name: ${bulletColorName}`);
  }

  // IN-PROGRESS: the next values should also be processed correctly

  // BULLET_COLOR = '_bullet_color_hex',
  // BULLET_COLOR_LEGACY = '_bullet_color_',
  // BULLET_COLOR_NAME = '_bullet_color_name_',
  // BULLET_COLOR_TINT = '_bullet_color_tint_',

  return result;
}

export function getParagraphFormat(block: Draft.RawDraftContentBlock): string | undefined {
  const { type } = block;

  // some typing mismatch ???
  switch (type as string) {
    case 'center':
      return 'center';
    case 'right':
      return 'end';
    case 'justify':
      return 'justify';
    case 'left':
    default:
      return '';
  }
}

export function addNoWrapStyles(child: TSerializedLexicalNode): TSerializedLexicalNode {
  const noWrapStylesString = Object.entries(NO_WRAP_STYLES_OBJECT)
    .map(([key, value]) => `${key}: ${value};`).join(' ');

  child.style += noWrapStylesString;

  return child;
}

export function generateNestedItem<T>(item: T, depth: number): T {
  const ul = {
    ...lexicalListNodeBase,
    version: 0,
    children: [] as typeof item[],
  };

  const li = {
    ...lexicalListItemNodeBase,
    indent: depth,
    children: [] as typeof ul[],
  };

  if (depth > 0) {
    ul.children.push(item);
    li.children.push(ul);

    return generateNestedItem<typeof li>(li, depth - 1) as unknown as T;
  }

  return item;
}

export const getBulletColorFromStyles = (styles: string, colors: any): Models.BrandColor | undefined => {
  const value = styles.split(';')
    .find(item => item.includes('bullet-color') || item.includes('bullet-color-name'))?.split(': ')[1];

  if (!value) {
    return undefined;
  }

  return getBrandColor(colors, value)?.toJS();
};

export const formatListNodes = (nodes: RawContentLine[], colors: Models.BrandColorsList):
SerializedMarkNode[] | SerializedLinkNode[] | TSerializedLexicalNode[] | SerializedCustomListNode[] | RawContentLine[] => {
  return nodes.reduce((acc, currNode, index) => {
    if (currNode.type === BulletListType.LIST_ITEM) {
      const indentedItem = generateNestedItem<typeof currNode>(currNode, currNode.indent);
      const bulletColor = getBulletColorFromStyles((currNode.children[0] as TSerializedLexicalNode).style, colors);

      const list = {
        ...lexicalListNodeBase,
        type: BulletListType.CUSTOM_LIST,
        children: [indentedItem],
        color: bulletColor,
        direction: LexicalFieldDescribes.LTR,
      };

      if (nodes[index - 1]?.type === BulletListType.LIST_ITEM) {
        (acc[acc.length - 1] as unknown as Partial<SerializedRootNode>).children?.push(indentedItem);

        return acc;
      }

      return [...acc, list];
    }

    return [...acc, currNode];
  }, []);
};
