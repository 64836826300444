import _, { isEqual } from 'lodash';
import { undoRedoKeyBindings } from 'modules/draftjs';
import { useHandleKeyCommand } from 'modules/draftjs/hooks/useHandleKeyCommand';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as UndoRedoModels from 'containers/UndoRedoControl/models';
import { customCallback } from 'models/UndoRedo';
import { eventEmitter, EMITTER_EVENTS } from 'utils/eventEmitter';
import { getLastFromStack, pushStateToStack, removeLastFromStack } from '../../utils/stack';
import { TextStateStack } from '../models';
import useCell from './useCell';
import useEditorDraftjs from './useEditor';
import useStyles from './useStyles';

enum UndoInputType {
  HISTORY_UNDO = 'historyUndo',
  HISTORY_REDO = 'historyRedo',
}

// [DCC-7844]: fixed bugs with numeric imput and undo/redo
const undoStackCancelInput = {
  type: '',
};

export function cancelUndoStack(event: InputEvent): void {
  if (event.inputType === UndoInputType.HISTORY_UNDO
    || event.inputType === UndoInputType.HISTORY_REDO) {
    undoStackCancelInput.type = event.inputType;
  } else {
    undoStackCancelInput.type = '';
  }
}

export type UndoMiddleware = <T extends customCallback>(
  callback: T,
  prop?: keyof TextStateStack
) => (...args: Parameters<T>) => ReturnType<T> | null;

type State = {
  undoStack: TextStateStack[];
  redoStack: TextStateStack[];
};

type HookProps = {
  editMode: boolean;
  isAutoFitContent: boolean;
  saveAppState: UndoRedoModels.ActionCreator.SaveAppState;
  cancel: UndoRedoModels.ActionCreator.Cancel;
};

type UndoHook = {
  undoStack: TextStateStack[];
  redoStack: TextStateStack[];
  isRedoDisabled: boolean;
  isUndoDisabled: boolean;
  undo: () => boolean;
  redo: () => boolean;
  undoStackMiddleware: UndoMiddleware;
  updateUndoRedoBeforeChange: () => void;
  fillUndoStackIfEmpty: () => void;
};

export default function useUndo(
  props: HookProps,
  editorHook: ReturnType<typeof useEditorDraftjs>,
  stylesHook: ReturnType<typeof useStyles>,
  cellHook: ReturnType<typeof useCell>,
): UndoHook {
  const [state, setState] = useState<State>({ undoStack: [], redoStack: [] });
  const stateRef = useRef(state);
  stateRef.current = state;

  // deep clone will be applied on push data to state
  const currentStackData: TextStateStack = {
    // editor
    editorState: editorHook.editorState,
    operations: editorHook.operations,
    activeFontFamily: editorHook.activeFontFamily,
    // cell
    cellHeight: cellHook.props.cellHeight,
    cellWidth: cellHook.props.cellWidth,
    isAutoFitContent: props.isAutoFitContent,
    // styles
    backgroundColor: stylesHook.styles.backgroundColor,
    backgroundColorOpacity: stylesHook.styles.backgroundColorOpacity,
    backgroundGradient: stylesHook.styles.backgroundGradient,
    backgroundImage: stylesHook.styles.backgroundImage,
    border: stylesHook.styles.border,
    borderRadius: stylesHook.styles.borderRadius,
    brandStyle: stylesHook.styles.brandStyle,
    brandStyleChanged: stylesHook.styles.brandStyleChanged,
    padding: stylesHook.styles.padding,
    verticalAlignment: stylesHook.styles.verticalAlignment,
  };
  const currentStackRef = useRef(currentStackData);
  currentStackRef.current = currentStackData;

  const { onEditorChange, setOperations, setActiveFontFamily } = editorHook;
  const { toggleProps } = cellHook;
  const { stylesSetters } = stylesHook;
  const applyStack = useCallback((stack: TextStateStack) => {
    const {
      editorState, operations, activeFontFamily,
      // cell
      cellHeight,
      cellWidth,
      // other
      isAutoFitContent,
      // styles
      border,
      backgroundColor,
      backgroundColorOpacity,
      backgroundGradient,
      backgroundImage,
      borderRadius,
      brandStyle,
      brandStyleChanged,
      padding,
      verticalAlignment,
    } = stack;

    onEditorChange(editorState);
    setOperations(operations);
    setActiveFontFamily(activeFontFamily);

    toggleProps(cellHeight, cellWidth, isAutoFitContent);

    stylesSetters.backgroundColor(backgroundColor);
    stylesSetters.backgroundColorOpacity(backgroundColorOpacity);
    stylesSetters.backgroundGradient(backgroundGradient, backgroundColor);
    stylesSetters.backgroundImage(backgroundImage);
    stylesSetters.border(border);
    stylesSetters.borderRadius(borderRadius);
    stylesSetters.brandStyle(brandStyle);
    stylesSetters.padding(padding);
    stylesSetters.verticalAlignment(verticalAlignment);
    stylesSetters.brandStyleChanged(brandStyleChanged);
  }, [
    onEditorChange,
    setOperations,
    setActiveFontFamily,
    toggleProps,
    stylesSetters,
  ]);

  const pushCurrentStateToStack = useCallback(
    (stack: TextStateStack[]): TextStateStack[] => pushStateToStack(stack, _.cloneDeep(currentStackRef.current)),
    [],
  );

  const undo = useCallback((): boolean => {
    const { undoStack: currentUndoStack, redoStack: currentRedoStack } = stateRef.current;
    if (!currentUndoStack.length) {
      return false;
    }
    applyStack(getLastFromStack(currentUndoStack));
    setState({
      undoStack: removeLastFromStack(currentUndoStack),
      redoStack: pushCurrentStateToStack(currentRedoStack),
    });

    return true;
  }, [applyStack]);

  const redo = useCallback((): boolean => {
    const { undoStack: currentUndoStack, redoStack: currentRedoStack } = stateRef.current;
    if (!currentRedoStack.length) {
      return false;
    }
    applyStack(getLastFromStack(currentRedoStack));
    setState({
      undoStack: pushCurrentStateToStack(currentUndoStack),
      redoStack: removeLastFromStack(currentRedoStack),
    });

    return true;
  }, [applyStack]);

  const updateUndoRedoBeforeChange = useCallback((): void => {
    const { undoStack: stack } = stateRef.current;
    setState({
      undoStack: pushCurrentStateToStack(stack),
      redoStack: [],
    });
  }, [setState]);

  const undoStackMiddleware: UndoMiddleware = useCallback((callback, prop?) => ((...args: Parameters<typeof callback>) => {
    if (undoStackCancelInput.type) {
      // if we are here that means that user pressed ctrl+z/y
      const result = callback(...args);
      if (undoStackCancelInput.type === UndoInputType.HISTORY_UNDO) {
        undo();
      } else if (undoStackCancelInput.type === UndoInputType.HISTORY_REDO) {
        redo();
      }
      undoStackCancelInput.type = '';

      return result as ReturnType<typeof callback>;
    }
    if (!prop || !isEqual(args[0], currentStackRef.current)) {
      updateUndoRedoBeforeChange();

      return callback(...args) as ReturnType<typeof callback>;
    }

    return null;
  }), [undo, redo, updateUndoRedoBeforeChange]);

  const fillUndoStackIfEmpty = useCallback(() => {
    if (!stateRef.current.undoStack.length) {
      updateUndoRedoBeforeChange();
    }
  }, [updateUndoRedoBeforeChange]);

  const handleKeyCommand = useHandleKeyCommand(fillUndoStackIfEmpty, undo, redo);
  const undoRedoGlobalHandler = useCallback((e: KeyboardEvent): void => {
    handleKeyCommand(undoRedoKeyBindings(e));
  }, [handleKeyCommand]);

  const isMountedRef = useRef(false);

  useEffect(() => {
    return () => {
      eventEmitter.removeListener(EMITTER_EVENTS.BODY_KEYDOWN, undoRedoGlobalHandler);
    };
  }, [undoRedoGlobalHandler]);

  const { undoStack, redoStack } = state;
  const isUndoDisabled = !undoStack.length;

  useEffect(() => {
    if (!isMountedRef.current) {
      return;
    }

    if (props.editMode) {
      eventEmitter.addListener(EMITTER_EVENTS.BODY_KEYDOWN, undoRedoGlobalHandler);
      props.saveAppState();
    } else {
      eventEmitter.removeListener(EMITTER_EVENTS.BODY_KEYDOWN, undoRedoGlobalHandler);
      if (isUndoDisabled) {
        props.cancel();
      }
      setState({ undoStack: [], redoStack: [] });
    }
  }, [props.editMode]);

  useEffect(() => {
    isMountedRef.current = true;
  }, []);

  return {
    undoStack,
    redoStack,
    isRedoDisabled: !redoStack.length,
    isUndoDisabled: !undoStack.length,
    undo,
    redo,
    undoStackMiddleware,
    updateUndoRedoBeforeChange,
    fillUndoStackIfEmpty,
  };
}
