import { useEffect, useRef } from 'react';
import * as UndoRedoModels from 'containers/UndoRedoControl/models';

type Props = {
  editMode: boolean;
  saveAppState: UndoRedoModels.ActionCreator.SaveAppState;
  cancel: UndoRedoModels.ActionCreator.Cancel;
};

export function useHistoryGlobalState(
  props: Props,
  isUndoDisabled: boolean,
): void {
  const { editMode, saveAppState, cancel } = props;
  const isMountedRef = useRef(false);

  useEffect(() => {
    if (!isMountedRef.current) {
      return;
    }

    if (editMode) {
      saveAppState();
    } else if (isUndoDisabled) {
      cancel();
    }
  }, [editMode]);

  useEffect(() => {
    isMountedRef.current = true;
  }, []);
}
