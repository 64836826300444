import { useEffect, useRef } from 'react';
import * as Constants from 'const';
import * as Models from 'models';
import { isDefaultBrandStyleAvailable } from 'utils/brandStyles';
import * as editorUtils from 'utils/editor';
import { TextEditorHook } from './TextEditorHook';
import { BrandProps } from './useBrandProps';
import useCell from './useCell';
import useStyles from './useStyles';

type HookProps = {
  editMode: boolean;
  document: Models.TextComponentMap;
  relation: Models.LayeredRegularRelationMap<Models.TextRelationStyles>;
  activeLayer: Constants.Layer;
  sectionStyles: Models.MasterScreen.SectionStylesMap;
};

export default function useEditMode(
  props: HookProps,
  brandProps: BrandProps,
  editorHook: TextEditorHook,
  stylesHook: ReturnType<typeof useStyles>,
  cellHook: ReturnType<typeof useCell>,
  setBrandStyle: (style: Models.BrandStyleMap, needToToggleAutoHeight: boolean) => void,
  storeText: () => void,
): void {
  const { editMode } = props;
  const isMountedRef = useRef(false);

  useEffect((): void => {
    if (!isMountedRef.current) {
      isMountedRef.current = true;

      return;
    }
    const { document, relation, activeLayer } = props;
    const { brandStyles, colors } = brandProps;

    cellHook.resetHeight();

    if (editMode) {
      // useDefaultStyles
      const noComponentAssigned = !relation.getIn(['documentId', activeLayer]) || !document;

      // IN-PROGRESS: is there a way to simplify it?
      const brandStyleId = relation.getIn(['styles', activeLayer, 'brandStyleId']);
      // NOTE: brand style and font color must be ignored if there is no document assigned
      if (!brandStyleId && noComponentAssigned) {
        if (isDefaultBrandStyleAvailable(brandStyles, props.sectionStyles)) {
          setBrandStyle(
            brandStyles.get(Constants.Styles.DefaultTextBrandStyle),
            false,
          );
          stylesHook.ensureStylesToBeKept();
        } else {
          editorHook.setters.fontColor(
            editorUtils.getBrandColor(colors, Constants.DefaultCustomStyle.FONT_COLOR),
          );
          stylesHook.ensureStylesToBeKept();
        }
      }
      editorHook.returnFocusToEditor();
      editorHook.clearOperations?.();
      cellHook.resetCellWidhtAndHeightChange();
    } else {
      storeText();
    }
  }, [editMode]);
}
