import Draft from 'draft-js';
import { useCallback, useState } from 'react';
import { getLastOperation } from 'components/ArtboardAssets/Text/utils/editor';
import * as Models from 'models';
import { compositeDecorator } from '../../../../modules/draftjs/decorators';
import { DraftjsEditorStateSetter } from '../../../../modules/draftjs/hooks/useDraftjsEditorState';

export type DraftjsEditorState = {
  editorState: Draft.EditorState;
  operations: Models.DraftEditorOperations;
  activeFontFamily: Models.FontFamily;
};

type Hook = {
  editorState: Draft.EditorState;
  setEditorState: DraftjsEditorStateSetter;
  setEditorStateAndAddOperations: DraftjsEditorStateSetter;
  operations: Models.DraftEditorOperations;
  setOperations: (operations: Models.DraftEditorOperations) => void;
  addOperation: (operation: Models.DraftEditorOperation) => void;
  addOperationsFromEditorState: (state: Draft.EditorState) => void;
  activeFontFamily: Models.FontFamily;
  setActiveFontFamily: (value: Models.FontFamily) => void;
};

export function useDraftjsEditorState(
  onInit: () => DraftjsEditorState,
  featureDecorators: boolean,
): Hook {

  const [state, setState] = useState<DraftjsEditorState>(() => {
    const value = onInit();
    if (!featureDecorators) {
      return value;
    }
    const { editorState, ...rest } = value;

    return {
      ...rest,
      editorState: Draft.EditorState.set(editorState, { decorator: compositeDecorator }),
    };
  });

  const setEditorState = useCallback<DraftjsEditorStateSetter>(
    arg => setState(prev => ({
      ...prev,
      editorState: typeof arg === 'function' ? arg(prev.editorState) : arg,
    })),
    [setState],
  );

  const setEditorStateAndAddOperations = useCallback<DraftjsEditorStateSetter>(
    arg => setState((prev) => {
      const newEditorState = typeof arg === 'function' ? arg(prev.editorState) : arg;
      const operation = getLastOperation(newEditorState, prev.editorState);

      return {
        editorState: newEditorState,
        operations: operation ? [...prev.operations, operation] : prev.operations,
        activeFontFamily: prev.activeFontFamily,
      };
    }),
    [setState],
  );

  const setOperations = useCallback(
    (operations: Models.DraftEditorOperations) => setState(prev => ({ ...prev, operations })),
    [setState],
  );

  const addOperation = useCallback(
    (operation: Models.DraftEditorOperation) => setState(
      prev => ({ ...prev, operations: prev.operations.concat(operation) }),
    ),
    [setState],
  );

  const addOperationsFromEditorState = useCallback(
    (newEditorState: Draft.EditorState) => setState((prev) => {
      const operation = getLastOperation(newEditorState, prev.editorState);

      return operation
        ? { ...prev, operations: [...prev.operations, operation] }
        : prev;
    }),
    [setState],
  );

  const setActiveFontFamily = useCallback(
    (activeFontFamily: Models.FontFamily) => setState(prev => ({ ...prev, activeFontFamily })),
    [setState],
  );

  return {
    ...state,
    setEditorState,
    setEditorStateAndAddOperations,
    setOperations,
    addOperation,
    addOperationsFromEditorState,
    setActiveFontFamily,
  };
}
