import classnames from 'classnames';
import Immutable from 'immutable';
import * as React from 'react';

import Spinner from 'components/Spinner';
import { ProcessType } from 'const';
import { LoadingOverlayOptionsMap } from 'models';
import { intlGet } from 'utils/intlGet';
import { ILoadingOverlayProps, ILoadingOverlayState, LoadingTitle } from './models';
import styles from './styles.module.scss';

export const ProcessTypeLoadingText: PartialRecord<ProcessType, LoadingTitle> = {
  [ProcessType.REFRESH]: {
    title: () => intlGet('Loading', 'Refreshing'),
    info: () => intlGet('Loading', 'ProcessInformation'),
  },
  [ProcessType.DUPLICATES_CHECKING]: {
    title: () => intlGet('Loading', 'DuplicatesChecking'),
    info: () => intlGet('Loading', 'ProcessInformation'),
  },
  [ProcessType.EXPORTING_FILES]: {
    title: () => intlGet('Loading', 'ExportingFiles'),
    info: () => intlGet('Loading', 'ProcessInformation'),
  },
  [ProcessType.UPLOAD_TO_PROMOMATS]: {
    title: () => intlGet('Loading', 'UploadingFilesToPromoMats'),
    info: () => intlGet('Loading', 'ProcessInformation'),
  },
  [ProcessType.IMPORT_TRANSLATION_PACKAGE]: {
    title: () => intlGet('Loading', 'ImportTranslationPackage'),
    info: () => intlGet('Loading', 'ProcessInformation'),
  },
  [ProcessType.SAVE_GROUPED_REUSABLE_LAYOUT]: {
    title: () => '',
    info: () => intlGet('Loading', 'SaveGroupedReusableLayout'),
  },
  [ProcessType.SAVE_REUSABLE_LAYOUT]: {
    title: () => '',
    info: () => intlGet('Loading', 'SaveReusableLayout'),
  },
};

export default class LoadingOverlay extends React.PureComponent<ILoadingOverlayProps, ILoadingOverlayState> {
  static readonly defaultProps: DefaultProps<ILoadingOverlayProps> = {
    options: Immutable.Map() as LoadingOverlayOptionsMap,
    spinnerSize: 'lg',
  };

  private readonly getClassNameModifier = (): string => {
    const { activeProcessType } = this.props;

    // TODO: Maybe it's better to create a class names map
    switch (activeProcessType) {
      case ProcessType.SAVE_LAYOUT_WITHOUT_MESSAGE:
        return 'save-reusable-layout-without-message';
      case ProcessType.SAVE_REUSABLE_LAYOUT:
      case ProcessType.SAVE_GROUPED_REUSABLE_LAYOUT:
        return 'save-reusable-layout';
      case ProcessType.DOCUMENT_UPLOADING:
        return 'document-uploading';
      case ProcessType.REFRESH:
      case ProcessType.DUPLICATES_CHECKING:
      case ProcessType.EXPORTING_FILES:
      case ProcessType.UPLOAD_TO_PROMOMATS:
      case ProcessType.IMPORT_TRANSLATION_PACKAGE:
        return 'project';
      case ProcessType.GET_PROJECT_DATA:
        return 'welcome-screen';
      default:
        return 'empty';
    }
  };

  render(): JSX.Element | null {
    const { isLocked, activeProcessType, options, spinnerSize } = this.props;

    if (!isLocked) {
      return null;
    }

    const classNameModifier = this.getClassNameModifier();
    const processText = ProcessTypeLoadingText[activeProcessType];
    const title = options?.get('title') || processText?.title();
    const info = options?.get('info') || processText?.info();

    return (
      <div className={classnames(styles.overlay, styles[`overlay_${classNameModifier}`])}>
        <div className={classnames(styles.overlayWindow, styles[`overlayWindow_${classNameModifier}`])}>
          <span className={styles.title}>{title}</span>
          <Spinner size={spinnerSize} />
          <span className={classnames(styles.info, styles[`info_${classNameModifier}`])}>{info}</span>
        </div>
      </div>
    );
  }
}
