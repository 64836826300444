import {
  INSERT_UNORDERED_LIST_COMMAND,
} from '@lexical/list';
import { $setBlocksType } from '@lexical/selection';
import { mergeRegister } from '@lexical/utils';
import {
  $createParagraphNode,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_HIGH,
  createCommand,
  FORMAT_ELEMENT_COMMAND,
  LexicalEditor,
} from 'lexical';

import * as Constants from 'const';
import * as Models from 'models';
import { BASE_PROPS_COMMAND } from '../BasePropsPlugin';
import { $getSelectionForListItem, $getTopLevelCustomListFromSelection } from './utils';

export const BULLET_TOGGLE = createCommand<string | undefined>();
export const BULLET_COLOR_COMMAND = createCommand<Models.BrandColorMap | undefined>();

export function registerCommands(
  editor: LexicalEditor,
): () => void {
  return mergeRegister(
    editor.registerCommand(
      BULLET_TOGGLE,
      () => {
        const selection = $getSelectionForListItem();
        if (selection) {
          $setBlocksType(selection, () => $createParagraphNode());
        } else {
          editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    ),
    editor.registerCommand(
      BULLET_COLOR_COMMAND,
      (color) => {
        const list = $getTopLevelCustomListFromSelection();
        if (list) {
          list.setColor(color?.toJS());

          return true;
        }

        return false;
      },
      COMMAND_PRIORITY_EDITOR,
    ),

    editor.registerCommand(
      BASE_PROPS_COMMAND.BLOCK_TYPE,
      (value) => {
        if (value === Constants.TextHorizontalAlignmentType.UNORDERED_LIST) {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
          editor.dispatchCommand(BULLET_TOGGLE, undefined);

          return true;
        }
        const isList = $getSelectionForListItem();
        if (isList) {
          editor.dispatchCommand(BULLET_TOGGLE, undefined);
        }

        return false;
      },
      COMMAND_PRIORITY_HIGH,
    ),
  );
}
