import _ from 'lodash';
import { batchActions } from 'redux-batched-actions';
import { call, put, select } from 'redux-saga/effects';
import guid from 'uuid';

import { ProjectPanelSection, ProjectPanelTab } from 'const';
import { setLastEditedLayoutId } from 'containers/App/actions';
import { mergeDocuments } from 'containers/Documents/actions';
import { documents as documentsSelector } from 'containers/Documents/selectors';
import { activeLayer } from 'containers/Project/selectors';
import { addActiveSection, setActiveTab, setAssetIdToScroll } from 'containers/ProjectPanel/actions';
import { mergeRelations } from 'containers/Relations/actions';
import { layeredRelations } from 'containers/Relations/selectors';
import { saveAppState } from 'containers/UndoRedoControl/actions';
import { callToActionFactory as createCallToAction } from 'factories/document/callToActionFactory';
import { textComponentFactory as createTextComponent } from 'factories/document/textComponentFactory';
import * as Models from 'models';
import { handleSagaError } from 'services/handleError';
import { Notifications } from 'services/Notifications';
import { isCallToAction, isTextComponent } from 'utils/entityType';
import { intlGet } from 'utils/intlGet';
import { Action } from '../models';

export function* copyCellContent(action: Action.ICopyCellContent): Generator {
  try {
    const { layoutId, relationId } = action.payload;

    const documents = (yield select(documentsSelector)).toJS() as Models.CombinedDocuments;
    const relations = (yield select(layeredRelations)).toJS() as Models.LayeredRelations;
    const layer: ReturnTypeSaga<typeof activeLayer> = yield select(activeLayer);

    const relation = relations[relationId] as Models.LayeredRegularRelation;
    const document = documents[relation.documentId[layer]];

    if (!isTextComponent(document) && !isCallToAction(document)) {
      return;
    }

    const newDocumentId = guid();
    const newDocument = isTextComponent(document)
      ? createTextComponent({ ...document, id: newDocumentId })
      : createCallToAction({ ...document, id: newDocumentId });

    const createdDocument = { [newDocumentId]: newDocument };
    const updatedRelation = {
      [relation.id]: {
        ...relation,
        documentId: _.set(relation.documentId, [layer], newDocumentId),
      },
    };

    const actions = [
      saveAppState(),
      mergeDocuments(createdDocument),
      setLastEditedLayoutId(layoutId),
      mergeRelations(updatedRelation),
    ] as Models.IAction[];

    if (isTextComponent(document)) {
      actions.push(
        setAssetIdToScroll(newDocumentId),
        setActiveTab(ProjectPanelTab.ASSETS),
        addActiveSection(ProjectPanelSection.TEXTS),
      );
    }

    yield put(batchActions(actions));

    const notificationMessage = isTextComponent(document) ? 'HardCopyOfTextComponentCreated' : 'HardCopyOfButtonComponentCreated';
    yield call(
      [Notifications, Notifications.success],
      intlGet('Notification.Success', notificationMessage),
      intlGet('Notification', 'Success'),
    );
  } catch (error) {
    yield call(handleSagaError, error, 'ArtboardCell.copyCellContent', 'CopyCellContent');
  }
}
