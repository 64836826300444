import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { CellAction, dispatchCellActions } from 'context/CellActions';
import { CellActionsContext } from 'context/CellActions/CellActionsContext';
import { useCallback, useContext, useEffect } from 'react';
import { registerCommands } from './commands';
import { $getAbbreviationData } from './utils';

export type LexicalAbbreviationValues = {
  abbreviationId: string | undefined;
  abbreviationTerm: string | undefined;
};

type Props = {
  onChange: (values: LexicalAbbreviationValues) => void;
};

// IN-PROGRESS removeAbbreviationFromEditorStateIfTermChanged

export function AbbreviationPlugin(props: Props): null {
  const { onChange } = props;
  const { relationId } = useContext(CellActionsContext);
  const handleClick = useCallback((): void => dispatchCellActions(
    relationId,
    [CellAction.TOGGLE_EDITMODE_ON, true],
    CellAction.OPEN_ABBREVIATIONS_PANEL,
  ), [relationId]);

  const [editor] = useLexicalComposerContext();

  useEffect(() => registerCommands(editor, handleClick), [editor]);

  useEffect(() => {
    const readEditorStateAndNotifyChange = (): void => {
      const { abbreviationId, abbreviationTerm } = $getAbbreviationData();
      onChange({
        abbreviationId,
        abbreviationTerm,
      });
    };
    editor.getEditorState().read(readEditorStateAndNotifyChange);

    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(readEditorStateAndNotifyChange);
    });
  }, [editor, onChange]);

  return null;
}
