import _ from 'lodash';
import raf from 'raf';
import React, { Component } from 'react';
import getDisplayName from 'react-display-name';
import { DragDropContextConsumer } from 'react-dnd';
import { findDOMNode } from 'react-dom';

import { createHorizontalStrength, createVerticalStrength, getNumberBetween } from 'utils/dndScrollZone';
import { DEFAULT_EDGE_SIZE } from 'utils/dndScrollZone/constants';
import { DndScrollZoneDefaultProps, DndScrollZoneProps, DndScrollZoneWithConsumerProps } from './models';

function createDndScrollZone<P extends object>(WrappedComponent: React.ComponentType<P> | string) {
  class DndScrollZone extends Component<P & DndScrollZoneProps & { forwardedRef: React.Ref<HTMLElement> }> {
    private readonly wrappedComponentRef = React.createRef<HTMLElement>();

    private clearMonitorSubscription: () => void;

    private container: HTMLElement;

    private frame: number | null = null;

    private attached = false;

    private dragging = false;

    private scaleX = 0;

    private scaleY = 0;

    static readonly displayName = `DndScrollZone(${getDisplayName(WrappedComponent)})`;

    static readonly defaultProps: DndScrollZoneDefaultProps = {
      verticalStrength: createVerticalStrength(DEFAULT_EDGE_SIZE),
      horizontalStrength: createHorizontalStrength(DEFAULT_EDGE_SIZE),
      onScrollChange: () => { },
      strengthMultiplier: 35,
    };

    componentDidMount(): void {
      this.container = findDOMNode(this.wrappedComponentRef.current) as HTMLElement;

      if (this.container && typeof this.container.addEventListener === 'function') {
        this.container.addEventListener('dragover', this.handleEvent);
      }

      this.clearMonitorSubscription = this.props.dragDropManager
        .getMonitor()
        .subscribeToStateChange(() => this.handleMonitorChange());
    }

    componentWillUnmount(): void {
      if (this.container && typeof this.container.removeEventListener === 'function') {
        this.container.removeEventListener('dragover', this.handleEvent);
      }

      this.clearMonitorSubscription();
      this.stopScrolling();
    }

    // Update scaleX and scaleY every 100ms or so
    // and start scrolling if necessary
    private readonly updateScrolling = _.throttle(
      (event: MouseEvent) => {
        const { left: x, top: y, width: w, height: h } = this.container.getBoundingClientRect();
        const point = { x: event.clientX, y: event.clientY };
        const box = { x, y, w, h };

        // calculate strength
        const { horizontalStrength, verticalStrength } = this.props;
        if (horizontalStrength) {
          this.scaleX = horizontalStrength(box, point);
        }
        if (verticalStrength) {
          this.scaleY = verticalStrength(box, point);
        }

        // start scrolling if we need to
        if (!this.frame && (this.scaleX || this.scaleY)) {
          this.startScrolling();
        }
      },
      100,
      { trailing: false },
    );

    private readonly handleEvent = (event: MouseEvent): void => {
      if (this.dragging && !this.attached) {
        this.attach();
        this.updateScrolling(event);
      }
    };

    private handleMonitorChange(): void {
      const isDragging = this.props.dragDropManager.getMonitor().isDragging();

      if (!this.dragging && isDragging) {
        this.dragging = true;
      } else if (this.dragging && !isDragging) {
        this.dragging = false;
        this.stopScrolling();
      }
    }

    private attach(): void {
      this.attached = true;
      window.document.body.addEventListener('dragover', this.updateScrolling);
    }

    private detach(): void {
      this.attached = false;
      window.document.body.removeEventListener('dragover', this.updateScrolling);
    }

    private startScrolling(): void {
      let i = 0;
      const tick = (): void => {
        const { strengthMultiplier, onScrollChange } = this.props;

        // stop scrolling if there's nothing to do
        if (strengthMultiplier === undefined || strengthMultiplier === 0 || this.scaleX + this.scaleY === 0) {
          this.stopScrolling();

          return;
        }

        i += 1;
        if (i % 2) {
          const {
            scrollLeft,
            scrollTop,
            scrollWidth,
            scrollHeight,
            clientWidth,
            clientHeight,
          } = this.container;

          const newLeft = this.scaleX
            ? this.container.scrollLeft = getNumberBetween(0, scrollWidth - clientWidth, scrollLeft + this.scaleX * strengthMultiplier)
            : scrollLeft;

          const newTop = this.scaleY
            ? this.container.scrollTop = getNumberBetween(0, scrollHeight - clientHeight, scrollTop + this.scaleY * strengthMultiplier)
            : scrollTop;

          if (onScrollChange !== undefined) {
            onScrollChange(newLeft, newTop);
          }
        }
        this.frame = raf(tick);
      };

      tick();
    }

    private stopScrolling(): void {
      this.detach();
      this.scaleX = 0;
      this.scaleY = 0;

      if (this.frame) {
        raf.cancel(this.frame);
        this.frame = null;
      }
    }

    render(): JSX.Element {
      const {
        // not passing down these props
        strengthMultiplier,
        verticalStrength,
        horizontalStrength,
        onScrollChange,
        dragDropManager,

        forwardedRef,
        ...props
      } = this.props;

      return (
        <WrappedComponent
          ref={(element): void => {
            (this.wrappedComponentRef as React.MutableRefObject<HTMLElement>).current = element;

            if (forwardedRef) {
              _.isFunction(forwardedRef)
                ? forwardedRef(element)
                : (forwardedRef as React.MutableRefObject<HTMLElement>).current = element;
            }
          }}
          {...props as P}
        />
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return React.forwardRef<HTMLElement>((props, ref) => <DndScrollZone {...props as any} forwardedRef={ref} />);
}

const createDndScrollZoneWithConsumer = <P extends object>(
  WrappedComponent: React.ComponentType<P> | string,
): React.ForwardRefExoticComponent<React.PropsWithoutRef<P & DndScrollZoneWithConsumerProps> & React.RefAttributes<HTMLElement>> => {
  const DnDScrollZone = createDndScrollZone<P>(WrappedComponent);

  return React.forwardRef<HTMLElement, P & DndScrollZoneWithConsumerProps>((props, ref) => (
    <DragDropContextConsumer>
      {({ dragDropManager }): JSX.Element | false => (
        dragDropManager !== undefined && <DnDScrollZone {...props} ref={ref} dragDropManager={dragDropManager} />
      )}
    </DragDropContextConsumer>
  ));
};

export default createDndScrollZoneWithConsumer;
