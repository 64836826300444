import React, { useEffect } from 'react';
import AccessibleDiv from 'components/AccessibleDiv/AccessibleDiv';
import { ToolbarDropdownType } from 'components/EditorToolbar/constants';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { TitleWithNamespace as Title } from 'components/Toolbar/constants';
import Dropdown from 'components/Toolbar/Shared/Dropdown';
import { HORIZONTAL_TEXT_ALIGNMENTS } from 'const';
import { TextHorizontalAlignmentType } from 'const/TextAlignment';
import { intlGet } from 'utils/intlGet';
import styles from './styles.module.scss';

type Props = {
  blockType: TextHorizontalAlignmentType;
  activeDropdown: ToolbarDropdownType | null;
  onToggle: (style: TextHorizontalAlignmentType) => void;
  wrapperOnMouseDownHackCallback: (e: React.MouseEvent<HTMLDivElement>, dropDownType: ToolbarDropdownType) => void;
  closeDialog: (e: MouseEvent, type: ToolbarDropdownType, isTarget: boolean) => void;
};

const TextHorizontalAlignmentControls: React.FunctionComponent<Props> = (props: Props) => {
  const iconRef = React.createRef<HTMLDivElement>();
  const { activeDropdown, closeDialog, blockType, onToggle, wrapperOnMouseDownHackCallback } = props;
  const showDropdown = activeDropdown === ToolbarDropdownType.TEXT_ALIGN_HORIZONTAL;
  const currentAlignObj = HORIZONTAL_TEXT_ALIGNMENTS.find(({ type }) => type === blockType);

  const closeDialogHandler = (event: MouseEvent): void => {
    closeDialog(event, ToolbarDropdownType.TEXT_ALIGN_HORIZONTAL, Boolean(iconRef?.current?.contains(event.target as Node)));
  };

  useEffect(() => {
    document.addEventListener('click', closeDialogHandler);

    return (): void => document?.removeEventListener('click', closeDialogHandler);
  }, [activeDropdown]);

  return (
    <div className={styles.TextAlignmentControls} data-title={Title.TEXT_ALIGNMENT_HORIZONTAL}>
      <AccessibleDiv
        className={styles.iconWrapper}
        onMouseDown={(e): void => wrapperOnMouseDownHackCallback(e, ToolbarDropdownType.TEXT_ALIGN_HORIZONTAL)}
        ref={iconRef}
        data-tooltip={intlGet('EditorToolbar.Tooltip', 'TextAlignmentHorizontal')}
      >
        <Icon
          type={currentAlignObj ? currentAlignObj.icon : IconType.TOOLBAR_LEFT}
          size="badge"
          color={showDropdown ? 'primary' : 'secondary'}
          hoverColor="primary-hover"
        />
      </AccessibleDiv>
      {
        showDropdown &&
        <Dropdown className={styles.Dropdown}>
          {
            HORIZONTAL_TEXT_ALIGNMENTS.map(({ type, icon, title }) => (
              <AccessibleDiv className={styles.item} key={title} onClick={onToggle.bind(null, type)} data-title={title}>
                <Icon type={icon} size="badge" color={type === blockType ? 'primary' : 'secondary'} />
                <span className={styles.hint}>{intlGet('EditorToolbar.TextAlignment.Horizontal', type as string)}</span>
              </AccessibleDiv>
            ))
          }
        </Dropdown>
      }
    </div>
  );
};

export default TextHorizontalAlignmentControls;
