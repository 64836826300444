import { IMap } from 'typings/DeepIMap';
import { useBeforeEditModeValue } from 'hooks/useBeforeEditModeValue';
import * as Models from 'models';
import { ResetHeightGroup, useResetHeight } from './useResetHeight';
import { ResetWidth, useResetWidth } from './useResetWidth';
import { StylesHook } from './useStyles';

type SizeResetHookProps = {
  editMode: boolean;
  cellHeight: number;
  cellWidth: number;
  isResizingRow: boolean;
  relation: Models.LayeredRegularRelationMap<Models.CallToActionStyles>;
  toggleRowAndNeighborsHeight: (newHeight: number, currentLayeredRelation?: Models.LayeredRelationsMap) => void;
  isAutoFitContent: boolean;
  layoutRelations: IMap<Models.LayeredRelations<Models.CombinedRelationStyles>>;
};

export type SizeResetHook = ResetHeightGroup & { cellWidthBeforeEdit: number; cellHeightBeforeEdit: number; resetWidth: ResetWidth };

export const useSizeReset = (
  props: SizeResetHookProps,
  stylesHook: StylesHook): SizeResetHook => {

  const { cellHeight, cellWidth, editMode } = props;

  const cellWidthBeforeEdit = useBeforeEditModeValue(cellWidth, editMode);
  const cellHeightBeforeEdit = useBeforeEditModeValue(cellHeight, editMode);

  const resetHeightGroup = useResetHeight(props, stylesHook, cellHeightBeforeEdit);

  const resetWidth = useResetWidth(stylesHook);

  return {
    ...resetHeightGroup,
    resetWidth,
    cellWidthBeforeEdit,
    cellHeightBeforeEdit,
  };

};
