import DraftEditor from '@draft-js-plugins/editor';
import Draft from 'draft-js';
import Immutable from 'immutable';
import { Features, useDraftjsEditor, useDraftjsEditorState } from 'modules/draftjs';
import { ReferencesEditorHook } from 'modules/References/models/ReferencesEditorHook';
import { RefObject } from 'react';
import * as Constants from 'const';

type UseTextEditorReturnType = ReferencesEditorHook & {
  editor: RefObject<DraftEditor>;
  editorState: Draft.EditorState;
  onEditorStateChange: (editorState: Draft.EditorState) => void;
};

export const useDraftjsReferencesEditor = (initialEditorState?: (() => Draft.EditorState)): UseTextEditorReturnType => {

  const draftjsState = useDraftjsEditorState(
    () => initialEditorState ? initialEditorState() : Draft.EditorState.createEmpty(),
    false,
  );
  const { editorState } = draftjsState;
  const {
    editorRef,
    resetEditorState,
    returnFocusToEditor,
    props,
    setters,
  } = useDraftjsEditor(
    draftjsState,
    {},
    [Features.CLEAR_FORMATTING, Features.LINK],
  );

  return {
    disableLink: !props.linkApplicable,
    editor: editorRef,
    editorState,
    fontStyles: props.inlineStyle as unknown as Immutable.OrderedSet<string>,
    link: props.link ?? '',
    focusEditor: returnFocusToEditor,
    onEditorStateChange: resetEditorState,
    toggleLink: (link: string) => setters.link(link || undefined),
    clearFormatting: (): void => {
      setters.clearFormatting();
    },
    toggleFontStyle: (value: string): void => {
      setters.inlineStyle(value);
    },
    toggleScriptStyle: (style: Constants.ScriptType): void => {
      setters.scriptStyle(style);
    },
  };
};
